/**
* @file: description
* @author: huguantao
* @Date: 2020-02-04 15:05:53
* @LastEditors: huguantao
* @LastEditTime: 2020-03-31 23:47:37
 */
// export function getQueryString(name) {
//     let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
//     let r = window.location.search.substr(1).match(reg);
//     if (r != null) return unescape(r[2]);
//     return null;
// }

// export function getLocationSearch() {
//     let url = window.location.search; //获取url中"?"符后的字串
//     let theRequest = {};
//     if (url.indexOf("?") !== -1) {
//         let str = url.substr(1);
//         let strs = str.split("&");
//         for (let i = 0; i < strs.length; i++) {
//             theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
//         }
//     }
//     return theRequest;
// }


// export function isEmpty(obj) {
//     for (let name in obj) {
//         return false;
//     }
//     return true;
// }

// export function getUA () {
//     let userAgent = navigator.userAgent || '';
//     if (/android/i.test(userAgent)) {
//         return 'android';
//     } else if (/ios/i.test(userAgent)) {
//         return 'ios';
//     }
//     return 'h5';
// }

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function fromatTimeFromMillsToMinute(mills) {
  const time = mills / 1000 / 3600;
  let minutes = 0;
  const minuteDig = time % 1;
  switch(minuteDig) {
    case 0:
      minutes = "00";
      break;
    case 0.25:
      minutes = 15;
      break;
    case 0.5:
      minutes = 30;
      break;
    case 0.75:
      minutes = 45;
      break;
    default:
      break;
  }
  return Math.floor(time) + ":" + minutes;
}

export function CompareDate (t1,t2) {
  var date = new Date();
  var a = t1.split(":");
  var b = t2.split(":");
  return date.setHours(a[0],a[1]) < date.setHours(b[0],b[1]);
}

export function getdayFromNum(data) {
  let days = [];
  data.map(item => {
    days.push(getDayByNum(item))
  })
  return days
}

export function getDayByNum(day) {
  switch(day) {
    case 1:
    case '1':
      return '一';
    case 2:
    case '2':
      return '二';
    case 3:
    case '3':
      return '三';
    case 4:
    case '4':
      return '四';
    case 5:
    case '5':
      return '五';
    case 6:
    case '6':
      return '六';
    case 7:
    case '7':
      return '日';
    default: 
      break;
  }
}