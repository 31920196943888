/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Table, DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { request } from '../../utils/request';

import '../../styles/teacher/detail.scss';

function TeacherDetail(props) {
  const teacherId = props.match.params.id;
  const [teacherDetail, setTeacherDetail] = useState({});
  const [leesonList, setLessonList] = useState([]);

  const [searchExpired, setSearchExpired] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});

  let history = useHistory();

  useEffect(() => {
    request(`/teacher/detail?teacherId=${teacherId}`, 'GET' ).then(res=> {
      setTeacherDetail(res)
    })
  }, [teacherId]);

  useEffect(() => {
    const urlParams = `&expired=${searchExpired}&courseInstanceStatus=${searchStatus}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/teacher/courseInstance/list?teacherId=${teacherId}${urlParams}`, 'GET' ).then(res=> {
      setLessonList(res.records)
    })
  }, [searchExpired, searchRange.endDate, searchRange.startDate, searchStatus, teacherId])

  const gotoLesson = (id) => {
    history.push(`/teacher/lessons/${id}`)
  }

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'createAt'
    },
    {
      title: '课程类型',
      dataIndex: 'courseTypeDesc'
    },
    {
      title: '课程类别',
      dataIndex: 'courseCategory'
    },
    {
      title: '舞蹈种类',
      dataIndex: 'danceCategory'
    },
    {
      title: '课程日期星期',
      dataIndex: 'weekDay',
      render: (text, row, index) => {
        return `${row.courseDate} 周${row.weekDay}`
      }
    },
    {
      title: '门店名称',
      dataIndex: 'studioName'
    },
    {
      title: '课程时间',
      dataIndex: 'startTime',
      render: (text, row, index) => {
        return `${row.startTime}-${row.endTime}`
      }
    },
    {
      title: '课价',
      dataIndex: 'coursePrice'
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text, row, index) => {
        return <i className={`iconfont icon-dui`}></i>
      }
    },
    {
      title: '操作',
      dataIndex: 'courseInstanceId',
      render: (text, row, index) => {
        return <div className="lesson-list-table-opt">
          <i className="iconfont icon-eye cursor" onClick={() => gotoLesson(text)}></i>
        </div>;
      },
    },
  ];

  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  return (
    <div className="teacher-detail-page common-page-container">
      <div>
        <div className="common-title-text">老师详情</div>
        <div className="box-shadow-card teacher-desc flex-center">
          <img src={teacherDetail.avatarUrl} alt=""/>
          <div className="flex-center">
            <table>
              <thead>
                <tr>
                  <th>老师姓名</th>
                  <th>老师邮箱</th>
                  <th>老师手机</th>
                  <th>老师简介</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{teacherDetail.userName}</td>
                  <td>{teacherDetail.userEmail}</td>
                  <td>{teacherDetail.mobile}</td>
                  <td>{teacherDetail.desc}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">课程记录</span>
            <Select className="inline-block filter-item" placeholder="状态"
              onChange={val => setSearchStatus(val)}>
              <Option value="">全部</Option>
              <Option value={1}>生效</Option>
              <Option value={0}>未生效</Option>
            </Select>
            <Select className="inline-block filter-item" placeholder="过期状态"
              onChange={val => setSearchExpired(val)}>
              <Option value="">全部</Option>
              <Option value="0">未过期</Option>
              <Option value="1">已过期</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
        </div>
        <Table columns={columns} dataSource={leesonList} bordered 
          rowClassName={function(record, index) {return record.status === 1 ? '' : 'ant-table-gray-row'}}
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
        
    </div>
  );
}

export default TeacherDetail;
