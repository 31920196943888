/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:03:05
* @LastEditors: huguantao
* @LastEditTime: 2021-06-08 23:44:17
 */
import React, {useState, useEffect, useRef} from 'react';
import { Drawer, Button, Upload, Form, Input  } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';

import reUploadImg from '../../assets/img/reUpload.png';
import '../../styles/shop/index.scss';
import '../../styles/drawerFormCommon.scss';

let uploadImgId = '';

function ShopIndex() {
  const [shopDetailVisible, setShopDetailVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [shopList, setShopList] = useState([]);
  const [shopDetail, setShopDetail] = useState({});

  const [newShopType, setNewShopType] = useState('edit');
  const [classromesText, setClassromesText] = useState(''); // 门店详情中的教室列表文案
  const [classromeList, setClassromeList] = useState([{}]); // 根据门店获取的教室列表
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');

  const statusRef = useRef();

  useEffect(() => {
    getShopList();
  }, []);

  const getShopList = () => {
    request(`/studio/list`, 'GET', {}, {} ).then(res=> {
      setShopList(res.records);
    })
  }

  const setUploadImgId = (id) => {
    uploadImgId = id;
  }

  const addNewShop = () => {
    setShopDetail({});
    showEditVisible();
    setUploadImgUrl('');
    setUploadImgId('');
    setClassromeList([{}]);
    setNewShopType('new')
  }
  
  const showDetailVisible = () => {
    setShopDetailVisible(true);
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideDetailtVisible = () => {
    setShopDetailVisible(false);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
    setShopDetail({});
  }

  const checkShop = (studioId) => {
    request(`/studio/detail?studioId=${studioId}`, 'GET', {studioId}, {} ).then(res=> {
      showDetailVisible();
      setShopDetail(res);
      setUploadImgUrl(res.imgUrl);
      setUploadImgId(res.imgId);
      setClassromeList(res.classRooms);

      let clasromeText = [];
      if(res.classRooms.length > 1) {
        res.classRooms.map(item => {
          clasromeText = clasromeText.concat(item.classRoomName)
        })
        setClassromesText(clasromeText.join(' '));
      } else {
        clasromeText = res.classRooms[0]?.classRoomName || '';
        setClassromesText(clasromeText);
      }
    })
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const addNewClassRoom = () => {
    const classRooms = classromeList;
    setClassromeList(classRooms.concat({classRoomName: ''}));
  }

  const changeClassRoom = (e, index) => {
    const classRooms = classromeList;
  
    classRooms[index + 1] = {
      classRoomName: e.target.value,
      classRoomId: e.target.dataset.id
    }
    setClassromeList([]); // 这样做是为了1、删除需要删除的部分 2、数组直接变更单个元素无法影响更新，先改变下长短
    setClassromeList(classRooms);
  }

  const removeClassRoom = (index) => {
    let classRooms = classromeList;
    setClassromeList(classRooms.splice(index + 1, 1)); // 这样做是为了1、删除需要删除的部分 2、数组直接变更单个元素无法影响更新，先改变下长短
    setTimeout(() => {
      setClassromeList(classRooms);
    });
  }

  const submitShop = (params) => {
    const submitParams = {
      studioId: shopDetail.studioId,
      classRooms: classromeList,
      status: statusRef.current.input.checked ? 1 : 0,
      imgId: uploadImgId,
      ...params
    }
    request(`${newShopType === 'new' ? '/studio/add' : '/studio/edit'}`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      getShopList();
    })
  }

  const ShopCard = (item) => {
    const data = item.data
    return <div className="add-shop-card cursor" onClick={() => checkShop(data.studioId)}>
      <img src={data.imgUrl} className="card-img" alt="" />
      <div className="card-title small-title-text">{data.studioName}</div>
      <div className="card-text gray-text-desc">{data.address}</div>
    </div>
  }

  return (
    <div className="shop-index-page common-page-container">
      <div className="common-title-text title">门店</div>
      <div className="shop-card-container">
        {
          shopList.map(item => {
            return <ShopCard data={item} key={item.studioId} />
          })
        }
        <div className="add-shop-card" onClick={addNewShop}>
          <div className="add-shop-card-content">
            <span className="add-icon text-center cursor">+</span>
            <span className="gray-text-desc">添加门店</span>
          </div>
        </div>
      </div>
      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={hideDetailtVisible}
        visible={shopDetailVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={hideDetailtVisible} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={() => {
              hideDetailtVisible();
              showEditVisible();
              setNewShopType('edit')
              }} 
              type="primary"
            >
              编辑
            </Button>
          </div>
        }
      >
        <div class="shopImg-wrap">
          <img src={shopDetail.imgUrl} className="shopImg" alt="" />
        </div>
        <JustifyText leftText="门店名称：" rightText={shopDetail.studioName} />
        <JustifyText leftText="门店地址：" rightText={shopDetail.address} />
        <JustifyText leftText="教室："  rightText={classromesText} />
        <GapLine />
        <div className="form-input">
          <Input type="checkbox" checked={shopDetail.status === 1} className="form-input-checkbox unEditable-checkbox-input" />生效
        </div>
      </Drawer>

      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <div class="placeholder-img-wrap">
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
              </div>
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
              <div style={{ marginTop: 8 }}>上传门店图片</div>
            </div>
          }
        </Upload>
        <Form
            onFinish={submitShop}
          >
            <Form.Item
              name="studioName"
              rules={[{ required: true, message: '请输入门店名称' }]}
              initialValue={shopDetail.studioName}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>门店名称： </span>
                <Input placeholder="请填写" defaultValue={shopDetail.studioName || ''} />
              </div>
            </Form.Item>
            <Form.Item
              name="address"
              rules={[{ required: true, message: '请输入门店地址' }]}
              initialValue={shopDetail.address}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>门店地址： </span>
                <Input placeholder="请填写" defaultValue={shopDetail.address || ''} />
              </div>
            </Form.Item>
            <div className="form-input display-flex">
              <span className="form-input-label"><span className="red-text">*</span>教室： &nbsp;&nbsp; &nbsp; &nbsp;</span>
              <Input data-id={classromeList && (classromeList[0]?.classRoomId || '')} 
                placeholder="请填写" defaultValue={classromeList && (classromeList[0]?.classRoomName || '')}
                onChange={(e => changeClassRoom(e, -1))} />
            </div>
            {
              classromeList && classromeList.length > 1 ? classromeList.slice(1, classromeList.length).map((item, index) => {
                return <div className="add-new-classroom add-new-input" key={index}>
                  <Input placeholder="请填写" data-id={item.classRoomId} defaultValue={item.classRoomName} onChange={(e => changeClassRoom(e, index))} />
                  <i className="iconfont icon-sub cursor" onClick={() =>removeClassRoom(index)}></i>
                </div>
              }) : null
            }
            
            <div className="add-new-classroom add-new-classroom1 cursor text-center" onClick={addNewClassRoom}>+添加教室</div>
            <div className="form-input">
              <Input type="checkbox" ref={statusRef} defaultChecked={newShopType === 'new' ? true : (shopDetail.status === 1)} className="form-input-checkbox" />生效
            </div>
            <div className="draw-footer">
              <Form.Item>
                <Button onClick={hideEditVisible}>取消</Button>
                <Button htmlType="submit" type="primary">确认</Button>
              </Form.Item>
            </div>
          </Form>
      </Drawer>
    </div>
  );
}


export default ShopIndex;
