/**
* @file: full calendar插件各种用法
  总览文档：https://fullcalendar.io/docs
  卡片渲染event：https://fullcalendar.io/docs/eventBackgroundColor
  插件列表：https://fullcalendar.io/docs/plugin-index
  时间和日期（横纵坐标）设置：https://fullcalendar.io/docs/slot-render-hooks
  时间格式：https://fullcalendar.io/docs/date-formatting
  react中的日历api使用：https://fullcalendar.io/docs/react，Calendar API章节
* @author: huguantao
* @Date: 2021-06-05 22:11:06
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:11:06
 */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment'
import { Button, Select, Form, Input, DatePicker, Drawer, Upload, TimePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import Toast from '../../components/Toast/Toast';

import { fromatTimeFromMillsToMinute, getBase64, getDayByNum } from '../../utils/helper';
import { request } from '../../utils/request';

import reUploadImg from '../../assets/img/reUpload.png';
import '../../styles/lesson/dashboard.scss';

// IMP: usestate在select组件中不更新了，偷懒写进全局来用。 每次编辑和新建，都要初始化他们
let courseType = '';
let studioId = '';
let classRoomId = '';
let teacherId = '';
// let timeRange = ['00:00', '00:00'];
let timeRange = ['', ''];
let courseCycle = [];
let uploadImgId = '';

let latestMonday = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD');
if(moment().format('E') == 7) {
  latestMonday = moment().format('YYYY-MM-DD');
}
function LessonDashBoard() {
  const [searchStudioId, setSearchStudioId] = useState('');
  const [searchClassRoomId, setSearchClassRoomId] = useState('');
  const [startDate, setStartDate] = useState(latestMonday);
  const [eventData, setEventData] = useState([]);

  // 枚举
  const [courseTypeEnum, setCourseTypeEnum] = useState([]);
  const [allStudio, setAllStudio] = useState([]);
  const [allClassroom, setAllClassroom] = useState([]);
  const [allTeachers, setAllTeachers] = useState([])

  // 编辑课程
  const [editVisible, setEditVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');
  // const [newCourseType, setNewCourseType] = useState('edit');
  // const [courseType, setCourseType] = useState('')
  // const [studioId, setStudioId] = useState();
  // const [classRoomId, setClassRoomId] = useState();
  // const [teacherId, setTeacherId] = useState();
  // const [timeRange, setTimeRange] = useState(['00:00', '00:00']);
  // const [courseCycle, setCourseCycle] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  const statusRef = useRef();
  const courseDetailFormRef = useRef(); // 表单实例
  let history = useHistory();
  const calendarRef = useRef();

  useEffect(() => {
    request("/studio/all?studioStatus=1", 'GET').then(res=> {
      setAllStudio(res);
      setSearchStudioId(res[0].studioId);
      request(`/studio/classRoom/search?studioId=${res[0].studioId}`, 'GET').then(resp=> {
        setAllClassroom(resp);
        setSearchClassRoomId(resp[0].classRoomId);
        getCourseList(res[0].studioId, resp[0].classRoomId, startDate);
      });
    })
    getAllTeachers();
  }, []);

  useEffect(() => {
    request(`/common/constants/all`, 'GET' ).then(res=> {
      setCourseTypeEnum(res.courseType);
    });
  }, []);

  // useEffect(() => {
  //   getCourseList();
  // }, [searchClassRoomId, startDate]);

  useEffect(()=> {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(startDate)
  }, [eventData, startDate]);

  const getCourseList = (searchStudioId, searchClassRoomId, startDate) => {
    const params = `studioId=${searchStudioId}&classRoomId=${searchClassRoomId}&startDate=${startDate}&instanceStatus=1`;
    request(`/course/instance/search?${params}`, 'GET').then(res=> {
      let allEvent = [];
      res.map(item => {
        return allEvent.push({
          id: item.courseInstanceId,
          title: item.courseCategory,
          start: item.courseDate + 'T' + item.startTime,
          end: item.courseDate + 'T' + item.endTime,
          className: `calendar-event-block ${getClassName(item)}`,
          data: {
            title: item.courseCategory,
            teacher: item.teacherName,
            desc: `${item.danceCategory}   ${item.danceName}`,
            timeRange: item.timeRange,
            orderCnt: item.orderCnt
          }
        });
      })
      setEventData(allEvent);
    })
  };

  const getClassName = (courseData) => {
    let className = '';
    // 课程结束时间小于当前时间，就是过期的，要置灰
    if(courseData.expired) {
      className = 'calendar-event-gray';
    } else {
      switch(courseData.courseType) {
        case 0: 
          className = 'calendar-event-blue';
          break;
        case 1: 
          className = 'calendar-event-green';
          break;
        case 2: 
          className = 'calendar-event-orange';
          break;
        default:
          className = 'calendar-event-ornage';
          break;
      }
    }
    return className;
  }

  const handleSearchStudioId = (studioId) => {
    setSearchStudioId(studioId);
    if(studioId) {
      getAllClassRoom(studioId)
    }
  }

  /**
   * momentjs api：moment().subtract(1,'months')
   * @param {*} type：months days
   * @param {*} num 
   */
  const changeStartDate = (num, type) => {
    // 获取当前周的开始结束时间
    function getCurrWeekDays (day) {
      let date = []
      let weekOfday = parseInt(moment(day).format('d')) // 计算今天是这周第几天 周日为一周中的第一天
      let start = moment(day).subtract(weekOfday, 'days').format('YYYY-MM-DD') // 周一日期
      let end = moment(day).add(7 - weekOfday - 1, 'days').format('YYYY-MM-DD') // 周日日期
      date.push(start)
      date.push(end)
      return date
    }

    let newStart = '';
    if(type === 'months') {
      const newDay = moment(startDate).subtract(type, num).format('YYYY-MM-DD');
      newStart = getCurrWeekDays(newDay)[0];
    } else {
      newStart = moment(startDate).subtract(type, num).format('YYYY-MM-DD');
    }

    setStartDate(newStart);
    getCourseList(searchStudioId, searchClassRoomId, newStart);
  }

  const handleEventClick = (data) => {
    const courseInstanceId = data.event._def.publicId;
    history.push(`/lesson/detail/${courseInstanceId}`)
  }

  const setUploadImgId = (id) => {
    uploadImgId = id;
  }
  const setSelectValueByKey = (key, val) => {
    switch(key) {
      case 'courseType':
        courseType = val;
        break;
      case 'studioId':
        studioId = val;
        if(val) {
          getFormClassRoom(val);
        }
        break;
      case 'classRoomId':
        classRoomId = val;
        break;
      case 'teacherId':
        teacherId = val;
        break;
      case 'timeRange': 
        timeRange = val;
        break;
      case 'courseCycle':
        courseCycle = val;
        break;
      default: 
        break
    }
  }
  
  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
  }

  const getAllClassRoom = (id) => {
    request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
      setAllClassroom(res);
      setSearchClassRoomId(res[0].classRoomId);
      getCourseList(id, res[0].classRoomId, startDate)
    });
  }

  const getFormClassRoom = (id) =>{
    request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
      setAllClassroom(res);
    });
  }
  const getAllTeachers = () => {
    request(`/teacher/search`, 'GET' ).then(res=> {
      setAllTeachers(res.records);
    });
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const onTimeRangeChange = (val) => {
    setSelectValueByKey('timeRange', [moment(val[0]).format('HH:mm'), moment(val[1]).format('HH:mm')]);
  }
  const onDateRangeChange = (val) => {
    setDateRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const submitCourse = (params) => {
    let submitParams = {
      courseType,
      studioId,
      classRoomId,
      teacherId,
      imgId: uploadImgId,
      startTime: timeRange[0],
      endTime: timeRange[1],
      instanceStatus: statusRef.current.input.checked ? 1 : 0,
      ...params,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      courseCycle
    }
    request(`/course/create`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      Toast.show({mess: '创建成功'});
      setUploadImgUrl('');
      setUploadImgId('');
      setSelectValueByKey('teacherId','');

      getCourseList(searchStudioId, searchClassRoomId, startDate);
    })
  }

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  
  const courseForm = useCallback(() => {
    return <Form onFinish={submitCourse} ref={courseDetailFormRef}>
      <Form.Item>
        <div className="form-input display-flex">
          <span className="form-input-label">课程类型： </span>
          <Select key="courseType" onChange={(val) => setSelectValueByKey('courseType',val)} 
            className="inline-block filter-item" 
            rules={[{ required: true, message: '请选择课程类型' }]}>
            {
              courseTypeEnum.map((item, index) => {
                return <Option value={item.code} key={index}>{item.desc}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="courseCategory" rules={[{ required: true, message: '请填写课程类别' }]} >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程类别： </span>
          <Input placeholder="请填写" />
        </div>
      </Form.Item>
      <Form.Item name="danceCategory" rules={[{ required: true, message: '请填写舞蹈种类' }]}>
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>舞蹈种类： </span>
          <Input placeholder="请填写" />
        </div>
      </Form.Item>
      <Form.Item name="danceName">
        <div className="form-input display-flex">
          <span className="form-input-label">舞蹈/歌曲名称： </span>
          <Input placeholder="请填写" />
        </div>
      </Form.Item>

      {/* 日期 */}
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>日期区间： </span>
          <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateRangeChange}
            />
        </div>
      </Form.Item>
      <Form.Item name="">
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程时间： </span>
          <TimePicker.RangePicker locale={locale} 
            // defaultValue={[moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]} 
            onChange={onTimeRangeChange} />
        </div>
      </Form.Item>
      <Form.Item name="" >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程周期： </span>
          <Select key="time" mode="multiple" onChange={(val) => setSelectValueByKey('courseCycle', val)} className="inline-block filter-item" >
            {
              [1,2,3,4,5,6,7].map((item) => {
              return <Option value={item} key={item}>星期{item}</Option>
            })
          }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="coursePrice">
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程价格： </span>
          <Input placeholder="请填写" type="number" />
        </div>
      </Form.Item>
      {/* 课程价格不可编辑 */}

      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">门店名称： </span>
          <Select key="studio" onSelect={(val) => setSelectValueByKey('studioId',val)} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allStudio.map((item, index) => {
                return <Option value={item.studioId} key={index}>{item.studioName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">教室名称： </span>
          <Select key="classroom" onSelect={(val) => setSelectValueByKey('classRoomId',val)} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allClassroom.map((item, index) => {
                return <Option value={item.classRoomId} key={index}>{item.classRoomName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">老师姓名： </span>
          <Select onSelect={(val) => setSelectValueByKey('teacherId',val)} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allTeachers.map((item, index) => {
                return <Option value={item.teacherId} key={index}>{item.teacherName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="ceilingCnt">
        <div className="form-input display-flex">
          <span className="form-input-label">人数上限： </span>
          <Input type="number" placeholder="请填写" />
        </div>
      </Form.Item>
      <Form.Item name="crowd">
        <div className="form-input display-flex">
          <span className="form-input-label">适合人群： </span>
          <Input placeholder="请填写" />
        </div>
      </Form.Item>
      <Form.Item name="cancelTime">
        <div className="form-input display-flex">
          <span className="form-input-label">取消时间： </span>
          <Input placeholder="请填写" type="number" />
        </div>
      </Form.Item>
      <Form.Item name="title">
        <div className="form-input display-flex">
          <span className="form-input-label">简介标题： </span>
          <Input placeholder="请填写" />
        </div>
      </Form.Item>
      <Form.Item name="content">
        <div className="form-input display-flex">
          <span className="form-input-label">正文编辑： </span>
          {/* <Input placeholder="请填写" /> */}
          <TextArea placeholder="请填写" className="form-textarea" />
        </div>
      </Form.Item>
      <div className="form-input">
        <Input type="checkbox" defaultChecked={true} ref={statusRef} className="form-input-checkbox" />生效
      </div>
      <div className="draw-footer">
        <Form.Item>
          <Button onClick={hideEditVisible}>取消</Button>
          <Button htmlType="submit" type="primary">确认</Button>
        </Form.Item>
      </div>
    </Form>
  }, [courseTypeEnum, onTimeRangeChange, allStudio, allClassroom, allTeachers, setSelectValueByKey])

  return (
    <div className="lesson-dashboard-page">
      <div className="lesson-dashboard-page-header">
        <div className="lesson-dashboard-page-header-left">
          <span className="lesson-dashboard-page-header-title">课程</span>
          <Select placeholder="门店" className="lesson-dashboard-page-header-item" value={searchStudioId}
            onChange={val => handleSearchStudioId(val)} suffixIcon={<i className="iconfont icon-jiantou-copy-copy"></i>}  
          >
            {/* <Option value={''}>所有门店</Option> */}
            {
              allStudio.map(item => {
                return <Option value={item.studioId} key={item.studioId}>{item.studioName}</Option>
              })
            }
          </Select>
          <Select placeholder="教室" className="lesson-dashboard-page-header-item" value={searchClassRoomId}
            onChange={val => {setSearchClassRoomId(val); getCourseList(searchStudioId, val, startDate);}} suffixIcon={<i className="iconfont icon-jiantou-copy-copy"></i>}
          >
            {/* <Option value={''}>所有教室</Option> */}
            {
              allClassroom.map(item => {
                return <Option value={item.classRoomId} key={item.classRoomId}>{item.classRoomName}</Option>
              })
            }
          </Select>
          <span className="lesson-dashboard-page-header-item">
            <span className="lesson-dashboard-page-header-item-time">{moment(startDate).format('YYYY.MM')}</span>
            <i onClick={() => changeStartDate(1, 'months')} className="iconfont icon-jiantou-copy-copy trans180 change-icon change-icon-redius cursor"></i>
            <i onClick={() => changeStartDate(-1, 'months')} className="iconfont icon-jiantou-copy-copy cursor"></i>
          </span>
          <div className="lesson-dashboard-page-header-item">
            <i onClick={() => changeStartDate(7, 'days')} className="iconfont icon-jiantou-copy-copy trans180 change-icon cursor"></i>
            <span className="lesson-dashboard-page-header-item-time">Week</span>
            <i onClick={() => changeStartDate(-7, 'days')} className="iconfont icon-jiantou-copy-copy change-icon cursor"></i>
          </div>
        </div>
        <div>
          <Button style={{marginRight: '20px', padding: 0}} onClick={() => history.push('/lesson/lists')}>创建记录</Button>
          <Button type="primary" onClick={showEditVisible}><i className="iconfont icon-add"></i>&nbsp;创建</Button>
        </div>
      </div>
      <FullCalendar
          ref={calendarRef}
          plugins={[ interactionPlugin, timeGridPlugin, dayGridPlugin, momentPlugin ]}
          initialView="timeGridWeek"
          allDaySlot={false}
          weekends={true}
          events={eventData}
          initialDate={startDate} // X轴起始日期（必填）
          // navLinks={true} // can click day/week names to navigate views
          editable={true}
          headerToolbar={{
            start: '', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: ''
          }}
          dayHeaderClassNames={"calendar-header"}
          dayMaxEvents={true} // allow "more" link when too many events
          // dayHeaderFormat={"dd MM.D"} // header格式化
          dayHeaderContent={function(data) {
            const day = getDayByNum(moment(data.date).format('E'));
            return `周${day} ${moment(data.date).format('MM.DD')}`
          }}
          slotMinTime={'00:00:00'} // Y轴格式设置 开始
          slotMaxTime={'24:00:00'}
          slotDuration={'00:15:00'}
          scrollTime={'10:00:00'}
          slotLabelInterval={{minute: 15}}
          slotLabelFormat={"HH:MM"} // Y轴格式设置 开始
          slotLabelClassNames={"calendar-slot"}
          slotLabelContent={function(date) { // Y轴时间渲染
            return fromatTimeFromMillsToMinute(date.time.milliseconds)
          }}
          eventContent={function(data) { // 渲染卡片内容
            const courseData = data.event._def.extendedProps.data;
            return <div className="calendar-event-block-content">
              <div className="calendar-event-block-content-title">
                <span>{courseData.title}</span>
                <span>{courseData.teacher}</span>
              </div>
              <div className="calendar-event-block-content-desc">{courseData.desc}</div>
              <div className="calendar-event-block-content-time">{courseData.timeRange}</div>
              <div className="calendar-event-block-content-desc">{courseData.orderCnt}人已预约</div>
            </div>
          }}
          eventMaxStack={1}
          eventClick={handleEventClick}
          eventMouseEnter={() => {}}
          eventMouseLeave={() => {}}
      />
      <div className="calendar-gap"></div>

      <Drawer
        id="drawer-edit-dash"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <div class="placeholder-img-wrap">
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
              </div>
              {/* <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" /> */}
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
              <div style={{ marginTop: 8 }}>上传课程图片</div>
            </div>
          }
        </Upload>
        {courseForm()}
      </Drawer>
    </div>
  );
}

export default LessonDashBoard;
