/**
* @file: description
* @author: huguantao
* @Date: 2021-06-04 16:15:08
* @LastEditors: huguantao
* @LastEditTime: 2021-06-07 22:22:11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/index.js';

import './styles/iconfont/iconfont.css';
import './index.css';
import './styles/common.scss';

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);

document.addEventListener('mousewheel', function(e) {
  if(e.target.nodeName === 'INPUT') {
    e.target.blur();
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
