/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:18
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:18
 */
import React, {useState, useEffect} from 'react';
import { Input, Table } from 'antd';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';

import '../../styles/teacher/lesson.scss';

function TeacherLesson(props) {
  const courseInstanceId = props.match.params.id;
  const [lessonDetail, setLessonDetail] = useState({});
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    request(`/course/courseInstance/detail?courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
      setLessonDetail(res)
    });
    request(`/course/courseInstance/order/list?courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
      setOrderList(res)
    });
  }, [courseInstanceId]);

  const textData1 = [{
    leftText: '课程类型',
    rightText: lessonDetail.courseTypeDesc
  }, {
    leftText: '课程类别',
    rightText: lessonDetail.courseCategory
  }, {
    leftText: '舞蹈种类',
    rightText: lessonDetail.danceCategory
  }, {
    leftText: '舞蹈/歌曲名称',
    rightText: lessonDetail.danceName
  }, {
    leftText: '课程日期',
    rightText: lessonDetail.courseDate
  }, {
    leftText: '课程星期',
    rightText: '星期' + lessonDetail.weekDay
  }, {
    leftText: '课程价格',
    rightText: lessonDetail.coursePrice + '课时'
  }, {
    leftText: '教室名称',
    rightText: lessonDetail.classRoomName
  }, {
    leftText: '老师姓名',
    rightText: lessonDetail.teacherName
  }, {
    leftText: '人数上限',
    rightText: lessonDetail.ceilingCnt
  }];

  const textData2 = [{
    leftText: '门店名称',
    rightText: lessonDetail.studioName
  }, {
    leftText: '门店地址',
    rightText: lessonDetail.studioAddress
  }, {
    leftText: '适合人群',
    rightText: lessonDetail.crowd
  }, {
    leftText: '取消规则',
    rightText: lessonDetail.cancelTime + '小时'
  }];

  const columns = [
    {
      title: '学员头像',
      dataIndex: 'avatarUrl',
      render: (text, row, index) => {
        return <img className="lesson-detail-right-table-headimg" src={text} alt="head" />;
      },
    },
    {
      title: '学员姓名',
      dataIndex: 'userName'
    },
    {
      title: '学员ID',
      dataIndex: 'userId'
    },
    {
      title: '学员电话',
      dataIndex: 'mobile'
    },
    {
      title: '学员邮箱',
      dataIndex: 'userEmail'
    }
  ];

  return (
    <div className="teacher-lesson-page common-page-container">
      <div className="teacher-lesson-page-left">
        <div className="common-title-text">待进行</div>
        <div className="box-shadow-card teacher-lesson-page-left-content">
          {
            textData1.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          <GapLine />
          {
            textData2.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          <GapLine />
          <div className="small-text-text">{lessonDetail.title}</div>
          <div className="small-text-text">{lessonDetail.desc}</div>
          <div className="form-input">
            <Input type="checkbox" checked={lessonDetail.status === 1 ? true : false} className="form-input-checkbox unEditable-checkbox-input" />生效
          </div>
        </div>

      </div>
      <div className="teacher-lesson-page-right">
        <div className="common-title-text">预约详情</div>
        <Table columns={columns} dataSource={orderList} bordered 
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
    </div>
  );
}

export default TeacherLesson;
