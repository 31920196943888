/**
* @file: description
* @author: huguantao
* @Date: 2021-06-04 18:19:49
* @LastEditors: huguantao
* @LastEditTime: 2021-06-06 23:41:10
 */
import React,{Component, Fragment} from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Nav from '../components/Nav';
import Header from '../components/Header';

import Login from '../pages/Login';
import ShopIndex from '../pages/shop/index';
import AdminIndex from '../pages/admin/index';

import LessonDashBoard from '../pages/lesson/dashBoard';
import LessonList from '../pages/lesson/createList';
import LessonListItem from '../pages/lesson/listItem';
import LessonDetail from '../pages/lesson/detail';

import StudentList from '../pages/student/list';
import StudentDetail from '../pages/student/detail';
import StudentLesson from '../pages/student/lesson';
import StudentRecharge from '../pages/student/recharge';
import StudentScore from '../pages/student/score';
import StudentAppoint from '../pages/student/appoint';

import TeacherList from '../pages/teacher/list';
import TeacherDetail from '../pages/teacher/detail';
import TeacherLesson from '../pages/teacher/lesson';

class Routes extends Component {
    render(){
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Fragment>
                        <div className="global-page-container">
                            <Nav />
                            <div className="global-page-content">
                                <Header />
                                <Switch>
                                    <Route path="/" component={LessonDashBoard} exact />
                                    <Route path="/shop" component={ShopIndex} />
                                    <Route path="/admin" component={AdminIndex} />

                                    <Route path="/lesson/dashboard" component={LessonDashBoard} />
                                    <Route path="/lesson/lists" component={LessonList} />
                                    <Route path="/lesson/list/item/:id" component={LessonListItem} />
                                    <Route path="/lesson/detail/:id" component={LessonDetail} />

                                    <Route path="/student/list" component={StudentList} />
                                    <Route path="/student/detail/:id" component={StudentDetail} />
                                    <Route path="/student/appoint/:id" component={StudentAppoint} />
                                    <Route path="/student/lessons/:id" component={StudentLesson} />
                                    <Route path="/student/score/:id" component={StudentScore} />
                                    <Route path="/student/recharge/:id" component={StudentRecharge} />

                                    <Route path="/teacher/list" component={TeacherList} />
                                    <Route path="/teacher/detail/:id" component={TeacherDetail} />
                                    <Route path="/teacher/lessons/:id" component={TeacherLesson} />
                                </Switch>
                            </div>
                        </div>
                    </Fragment>

                </Switch>
            </BrowserRouter>
        )
    }
}

export default Routes
