/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Drawer, Input, Select, Button, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';
import { debounce } from  '../../utils/useDebounce';

import appointImg from '../../assets/img/student/appoint.png';
import rechargeImg from '../../assets/img/student/recharge.png';
import lessonImg from '../../assets/img/student/lesson.png';
import pointImg from '../../assets/img/student/point.png';

import reUploadImg from '../../assets/img/reUpload.png';
import editIcon from '../../assets/img/icon-edit.png';
import '../../styles/student/detail.scss';

let avatarId ='';

function StudentDetail(props) {
  const studentId = props.match.params.id;
  const [studentDetail, setStudentDetail] = useState({});
  
  const [DetailVisible, setDetailVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [avatarId, setAvatarId] = useState('');
  const [allSubUsers, setAllSubUsers] = useState([]);
  const [subUsers, setSubUsers] = useState([]);
  const [subUsersEmail, setSubUsersEmail] = useState([]);
  // const [searchUserInput, setSearchUserInput] = useState('');

  const statusRef = useRef();
  let history = useHistory();

  useEffect(() => {
    getStudentDetail();
  }, [studentId]);

  useEffect(() => {
    request(`/student/search/list?accountType=1`, 'GET' ).then(res=> {
      setAllSubUsers(res.records || []);
    });
  }, []);

  // const searchSubUser = (val) => {
  //   request(`/student/subAccount/search?input=${val}`, 'GET' ).then(res=> {
  //     setAllSubUsers(res.records || []);
  //   });
  // };

  // const onSearchUserInput = (val) => {
  //   searchSubUser(val);
  //   setSearchUserInput(val)
  // }

  const setAvatarId = (id) => {
    avatarId = id;
  }

  const getStudentDetail = () => {
    const urlParams = `studentId=${studentId}&ext=true`;
    request(`/student/detail?${urlParams}`, 'GET' ).then(res=> {
      setStudentDetail(res);
      setUploadImgUrl(res.avatarUrl);
      setAvatarId(res.avatarId);

      let users = [];
      let emails = [];
      res.subAccounts && res.subAccounts.map(item => {
        emails.push(item.userEmail)
        return users.push(item.userId)
      });
      setSubUsers(users);
      setSubUsersEmail(emails);
    })
  }

  const gotoPage = (pageName) => {
    history.push(`/student/${pageName}/${studentId}`)
  }

  const showDetailVisible = () => {
    setDetailVisible(true);
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideDetailtVisible = () => {
    setDetailVisible(false);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setAvatarId(info.file.response.data.imageId)
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const submitStudent = (params) => {
    const submitParams = {
      studentId: studentDetail.userId,
      userStatus: statusRef.current.input.checked ? 1 : 0,
      avatarId,
      subUserIds: subUsers,
      ...params
    }
    request(`/student/edit`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      getStudentDetail();
    })
  }
  
  const { Option } = Select;
  return (
    <div className="student-detail-page common-page-container">
      <div>
        <div className="common-title-text">学员</div>
        <div>
          <div className="box-shadow-card student-desc">
            <i className="cursor iconfont icon-write1 icon-student-edit" onClick={showDetailVisible}>
              <img src={editIcon} alt="" />
            </i>
            <div className="flex-center" style={{justifyContent: 'flex-start'}}>
              <img className="img" src={studentDetail.avatarUrl} alt=""/>
              <div className="flex-center">
                <table>
                  <thead>
                    <tr>
                      <th>学员姓名</th>
                      <th>学员邮箱</th>
                      <th>学员手机</th>
                      <th>关联账户</th>
                      <th>Open ID</th>
                      {
                        studentDetail.accountType === 0 ? <th>昵称</th> : null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{studentDetail.userName}</td>
                      <td>{studentDetail.userEmail}</td>
                      <td>{studentDetail.mobile}</td>
                      <td>{studentDetail.subAccounts ? studentDetail.subAccounts.length : 0}</td>
                      <td>{studentDetail.openId}</td>
                      {
                        studentDetail.accountType === 0 ? <td>{studentDetail.nickName}</td> : null
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <GapLine />
            <div className="student-cards">
              <div className="student-card">
                <p className="small-text-text">
                  <i className="iconfont icon-caifu"></i>  剩余金额
                </p>
                <div className="common-title-text">{studentDetail.amount}</div>
              </div>
              <div className="student-card">
                <p>
                  <i className="iconfont icon-kuaixun"></i>  剩余课时
                </p>
                <div className="common-title-text">{studentDetail.classNum}</div>
              </div>
              <div className="student-card">
                <p>
                  <i className="iconfont icon-jifen"></i>  积分
                </p>
                <div className="common-title-text">{studentDetail.score}</div>
              </div>
            </div>
          </div>
          <div className="click-cards">
            <div className="box-shadow-card click-card cursor" onClick={() => gotoPage('appoint')}>
              <div className="common-title-text">预约
                <img src={appointImg} alt=""/>
              </div>
            </div>
            <div className="box-shadow-card click-card cursor" onClick={() => gotoPage('recharge')}>
              <div className="common-title-text">充值
                <img src={rechargeImg} alt=""/>
              </div>
            </div>
            <div className="box-shadow-card click-card cursor" onClick={() => gotoPage('lessons')}>
              <div className="common-title-text">课时
                <img src={lessonImg} alt=""/>
              </div>
            </div>
            <div className="box-shadow-card click-card cursor" onClick={() => gotoPage('score')}>
              <div className="common-title-text">积分
                <img src={pointImg} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={hideDetailtVisible}
        visible={DetailVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={hideDetailtVisible} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={() => {
              hideDetailtVisible();
              showEditVisible();
              }} 
              type="primary"
            >
              编辑
            </Button>
          </div>
        }
      >
        <img src={studentDetail.avatarUrl} className="shopImg headImg" alt="" />
        <JustifyText leftText="学员姓名：" rightText={studentDetail.userName} />
        <JustifyText leftText="学员邮箱：" rightText={studentDetail.userEmail} />
        <JustifyText leftText="学员手机：" rightText={studentDetail.mobile} />
        <GapLine />
        {
          studentDetail.accountType === 0 ? <>
            <JustifyText leftText="关联子账号：" rightText="" />
            {
              studentDetail.subAccounts && studentDetail.subAccounts.map(item => {
                return <JustifyText leftText={item.userName} rightText={item.userEmail} key={item.userId} />
              })
            }
            <GapLine />
          </> : ''
        }
        
        <div className="form-input">
          <Input type="checkbox" checked={studentDetail.userStatus === 1} className="form-input-checkbox unEditable-checkbox-input" />生效
        </div>
      </Drawer>
      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader headImg-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
          disabled={studentDetail.accountType === 0}
        >
          {
            uploadImgUrl ? <>
              <img src={uploadImgUrl} alt="avatar" className="ant-upload-placeholder-img" />
              {
                studentDetail.accountType === 0 ? null :
                <i className="iconfont icon-upload-img">
                  <img src={reUploadImg} alt=""></img>
                </i>
              }
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
            </div>
          }
        </Upload>
        <Form
            onFinish={submitStudent}
          >
            {/* <Form.Item name="" >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>OpenId： </span>
                <Input defaultValue={studentDetail.openId} disabled />
              </div>
            </Form.Item> */}
            <Form.Item name="userName" initialValue={studentDetail.userName} >
              <div className="form-input display-flex">
                <span className="form-input-label">学员姓名： </span>
                <Input placeholder="请填写" defaultValue={studentDetail.userName || ''} />
              </div>
            </Form.Item>
            <Form.Item
              name="userEmail"
              rules={[{ required: true, message: '请输入学员邮箱' }]}
              initialValue={studentDetail.userEmail}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>学员邮箱： </span>
                <Input placeholder="请填写" defaultValue={studentDetail.userEmail} />
              </div>
            </Form.Item>
            <Form.Item name="mobile" initialValue={studentDetail.mobile}>
              <div className="form-input display-flex">
                <span className="form-input-label">学员手机： </span>
                <Input placeholder="请填写" defaultValue={studentDetail.mobile} />
              </div>
            </Form.Item>
            
            {
              studentDetail.accountType === 0 ? <Form.Item name="">
                <div className="form-input display-flex">
                  <span className="form-input-label">关联子账户： </span>
                  <Select placeholder="输入学员姓名或邮箱搜索" 
                    defaultValue={subUsersEmail} mode="multiple" style={{width: '100%'}}
                    autoClearSearchValue={false}
                    filterOption={(inputValue, option) =>{
                      const name = option.name.toLowerCase();
                      const email = option.email.toLowerCase();
                      const val = inputValue.toLowerCase();
                      return name.indexOf(val) > -1 ||email.indexOf(val) > -1;
                      // return option.name.indexOf(inputValue) > -1 || option.email.indexOf(inputValue) > -1
                    }}
                    onChange={(val, opt) => {
                      let users = [];
                      opt.map(item => {
                        return users.push(item.key)
                      })
                      setSubUsers(users)
                    }}
                    // onSearch={debounce(val => onSearchUserInput(val), 1000)} 
                  >
                    {
                      allSubUsers.map((item) => {
                        return <Option value={item.userId} key={item.userId} 
                          name={item.userName} email={item.userEmail}>
                          <div className="add-order-user-option">
                            <p ><img src={item.avatarUrl} alt=""></img>{item.userName}</p>
                            <p>{item.userEmail}</p>
                          </div>
                        </Option>
                      })
                    }
                  </Select>
                </div>
              </Form.Item> : null
            }
            
            <div className="form-input">
              <Input type="checkbox" ref={statusRef} defaultChecked={studentDetail.userStatus === 1} className="form-input-checkbox" />生效
            </div>
            <div className="draw-footer">
              <Form.Item>
                <Button onClick={hideEditVisible}>取消</Button>
                <Button htmlType="submit" type="primary">确认</Button>
              </Form.Item>
            </div>
          </Form>
      </Drawer>

    </div>
  );
}

export default StudentDetail;
