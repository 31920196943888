/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:41:52
* @LastEditors: huguantao
* @LastEditTime: 2021-06-07 22:27:29
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { request } from '../../utils/request';
import UserImg from '../../assets/img/user.png';
import './index.scss';

function Header() {
  const userName = localStorage.getItem('userName') || '用户';

  let history = useHistory();

  const logout = () => {
    // 登出
    request(`/logout`, 'POST' ).then(res=> {
      localStorage.setItem('userName', '未登录');
      history.push('/login');
    })
  }

  return (
    <div className="header-component">
      <div className="header-component-user">
        <img src={UserImg} className="header-component-head" alt="user" />
        <span>{userName}</span>
      </div>
      <div className="header-component-quit cursor" onClick={logout}>退出</div>
    </div>
  );
}

export default Header;
