/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Drawer, Input, Select, Button, Table, DatePicker, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';
import { debounce } from  '../../utils/useDebounce';
import Toast from '../../components/Toast/Toast';

import reUploadImg from '../../assets/img/reUpload.png';
import '../../styles/student/list.scss';
import '../../styles/drawerFormCommon.scss';

let uploadImgId = '';

function StudentList() {
  const [searchInput, setSearchInput] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});
  const [studentList, setStudentList] = useState([]);

  // 学员创建
  const [createVisible, setCreateVisible] = useState(false);
  const [accountVisible, setAccountVisible] = useState(false);
  const [accountType, setAccountType] = useState(); // 0普通账户  1子账户
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');
  const [allSubUsers, setAllSubUsers] = useState([]);
  const [subUsers, setSubUsers] = useState([]);

  let history = useHistory();
  const statusRef = useRef();

  useEffect(() => {
    searchInput.length < 1 && getStudentList();
  }, [searchInput, searchType, searchStatus, searchRange.endDate, searchRange.startDate]);

  useEffect(() => {
    request(`/student/search/list?accountType=1`, 'GET' ).then(res=> {
      setAllSubUsers(res.records || []);
    });
  }, []);

  const setUploadImgId = (id) =>{
    uploadImgId = id;
  }

  const getStudentList = () => {
    const urlParams = `input=${searchInput}&accountType=${searchType}&status=${searchStatus}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/student/search/list?${urlParams}`, 'GET' ).then(res=> {
      setStudentList(res.records)
    })
  }

  const columns = [
    {
      title: '学员头像',
      dataIndex: 'avatarUrl',
      render: (text, row, index) => {
        return <img className="student-list-right-table-headimg" src={text} alt="" />;
      }
    },
    {
      title: '学员姓名',
      dataIndex: 'userName'
    },
    {
      title: '学员昵称',
      dataIndex: 'nickName'
    },
    {
      title: '创建时间',
      dataIndex: 'createAt'
    },
    {
      title: 'OpenID',
      dataIndex: 'openId'
    },
    {
      title: '学员电话',
      dataIndex: 'mobile'
    },
    {
      title: '学员邮箱',
      dataIndex: 'userEmail'
    },
    {
      title: '账户类型',
      dataIndex: 'accountTypeDesc'
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text, row, index) => {
        return <i className="iconfont icon-dui"></i>
      }
    },
    {
      title: '操作',
      dataIndex: 'userId',
      render: (text, row, index) => {
        return <div className="lesson-list-table-opt">
          <i className="iconfont icon-eye cursor" onClick={() => gotoDetail(text)}></i>
        </div>;
      },
    },
  ];

  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const gotoDetail = (id) => {
    history.push(`/student/detail/${id}`)
  }

  const chooseAccountType = (type) => {
    setAccountType(type);
    setAccountVisible(true);
    setUploadImgUrl('');
    setUploadImgId('');
    // setAllSubUsers([]);
    setSubUsers([]);
  }
  
  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  // const searchSubUser = (input) => {
  //   request(`/student/subAccount/search?input=${input}`, 'GET' ).then(res=> {
  //     setAllSubUsers(res.records || []);
  //   });
  // }

  // const onSearchUserInput = (val) => {
  //   searchSubUser(val);
  // }

  const submitCreate = (params) => {
    let submitParams;
    if(accountType === 1) {
      submitParams = {
        userStatus: statusRef.current.input.checked ? 1 : 0,
        avatarId: uploadImgId,
        accountType: 1,
        ...params
      }
    } else {
      submitParams = {
        userStatus: statusRef.current.input.checked ? 1 : 0,
        avatarId: uploadImgId,
        accountType: 0,
        subUserIds: subUsers,
        ...params
      }
    }
    Toast.show({type: 'loading'});
    request(`/student/add`, 'POST', submitParams ).then(res=> {
      Toast.hide();
      Toast.show({mess: '创建成功'});
      getStudentList();

      setCreateVisible(false);
      setAccountVisible(false);
      setAccountVisible(false);
    })
  }

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  return (
    <div className="student-list-page common-page-container">
      <div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">学员</span>
            <Input placeholder="输入后按回车键搜索" className="inline-block filter-item" onChange={e => setSearchInput(e.target.value)} onPressEnter={getStudentList}></Input>
            <Select placeholder="生效状态" className="inline-block filter-item" onChange={val => setSearchStatus(val)}>
              <Option value="">全部</Option>
              <Option value={1}>生效</Option>
              <Option value={0}>未生效</Option>
            </Select>
            <Select placeholder="全部账户" className="inline-block filter-item" onChange={val => setSearchType(val)}>
              <Option value="">全部账户</Option>
              <Option value={1}>子账户</Option>
              <Option value={0}>普通账户</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
          <div className="opts">
            <Button type="primary" onClick={() => setCreateVisible(true)}><i className="iconfont icon-add"></i>&nbsp;创建</Button>
          </div>
        </div>
        <Table columns={columns} dataSource={studentList} bordered 
          rowClassName={function(record, index) {return record.status === 1 ? '' : 'ant-table-gray-row'}}
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>

      <Drawer
        id="drawer-type"
        width={380}
        className="drawer-choose-account-type"
        placement="right"
        closable={false}
        maskClosable={true}
        onClose={() => {setCreateVisible(false); setAccountType('')}}
        visible={createVisible}
        destroyOnClose={false}
      >
        <div className="choose-create-type">
          <div className="choose-create-type-content">
            <div className="create-add-btn cursor" onClick={() => chooseAccountType(0)}><span>+</span></div>
            <p>普通账户</p>
          </div>
          <div className="choose-create-type-content">
            <div className="create-add-btn cursor" onClick={() => chooseAccountType(1)}><span>+</span></div>
            <p>子账户</p>
          </div>
        </div>
      </Drawer>

      <Drawer
        id="drawer-subcount"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setAccountVisible(false)}
        visible={accountVisible}
        destroyOnClose={true}
      >
        {
          accountType === 0 ? null : 
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader headImg-uploader"
            showUploadList={false}
            action="/api/v1/admin/image/upload"
            accept="image/jpeg, image/png"
            onChange={handleImgInput}
          >
            {
              uploadImgUrl ? <>
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
                <i className="iconfont icon-upload-img">
                  <img src={reUploadImg} alt=""></img>
                </i>
              </>: 
              <div>
                {uploadLoading ? <LoadingOutlined /> : 
                  <span className="add-icon text-center cursor">+</span>}
              </div>
            }
          </Upload>
        }
        
        <Form
            onFinish={submitCreate}
          >
            <Form.Item name="userName" 
              rules={[{ required: accountType === 1 ? true : false, message: '请输入学员姓名' }]}>
              <div className="form-input display-flex">
                <span className="form-input-label">
                  {
                    accountType === 1 ? <span className="red-text">*</span> : null
                  }
                  学员姓名： </span>
                <Input placeholder="请填写" />
              </div>
            </Form.Item>
            <Form.Item
              name="userEmail"
              rules={[{ required: true, message: '请输入学员邮箱' }]}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>学员邮箱： </span>
                <Input placeholder="请填写" />
              </div>
            </Form.Item>
            <Form.Item name="mobile">
              <div className="form-input display-flex">
                <span className="form-input-label">学员手机： </span>
                <Input placeholder="请填写" type="number" />
              </div>
            </Form.Item>
            <GapLine />
            {
              accountType === 0 ? 
              <Form.Item name="">
                <div className="form-input display-flex">
                  <span className="form-input-label">关联子账户： </span>
                  <Select placeholder="输入学员姓名或邮箱搜索" mode="multiple" style={{width: '100%'}}
                    filterOption={(inputValue, option) =>{
                      const name = option.name.toLowerCase();
                      const email = option.email.toLowerCase();
                      const val = inputValue.toLowerCase();
                      return name.indexOf(val) > -1 ||email.indexOf(val) > -1;
                      // return option.name.indexOf(inputValue) > -1 || option.email.indexOf(inputValue) > -1
                    }}
                    onChange={(val, opt) => {
                      let users = [];
                      opt.map(item => {
                        return users.push(item.key)
                      })
                      setSubUsers(users)
                    }}
                    // onSearch={debounce(val => onSearchUserInput(val), 1000)}
                  >
                    {
                      allSubUsers.map((item) => {
                      return <Option value={item.userId} key={item.userId}
                        name={item.userName} email={item.userEmail}>
                        <div className="add-order-user-option">
                          <p ><img src={item.avatarUrl} alt=""></img>{item.userName}</p>
                          <p>{item.userEmail}</p>
                        </div>
                      </Option>
                    })
                  }
                  </Select>
                </div>
              </Form.Item> : null
            }
            <div className="form-input">
              <Input type="checkbox" ref={statusRef} defaultChecked={true} className="form-input-checkbox" />生效
            </div>
            <div className="draw-footer">
              <Form.Item>
                <Button onClick={() => setAccountVisible(false)}>取消</Button>
                <Button htmlType="submit" type="primary">确认</Button>
              </Form.Item>
            </div>
          </Form>
      </Drawer>

    </div>
  );
}

export default StudentList;
