/**
* @file: GapLine 横向1px灰色分割线
* @author: huguantao
* @Date: 2021-06-08 23:26:00
* @LastEditors: huguantao
* @LastEditTime: 2021-06-08 23:32:41
 */
import React from 'react';
import './index.scss';

function GapLine() {
  return (
    <div className="component-gap-line" />
  );
}

export default GapLine;
