/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect} from 'react';
import { Select, Table, DatePicker, Drawer, Form, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { request } from '../../utils/request';
import JustifyText from '../../components/justifyText';
import Toast from '../../components/Toast/Toast';
import GapLine from '../../components/GapLine';

import '../../styles/student/list.scss';

function StudentAppoint(props) {
  const studentId = props.match.params.id;
  const [orderList, setOrderList] = useState([]); // 预约列表
  const [orderDetail, setOrderDetail] = useState({}); // 课程详情
  const [detailVisible, setDetailVisible] = useState(false);
  const [extraParams, setExtraParams] = useState({}); // 取消预约课程，需要提交userId和orderId 从list数据中获取

  // 列表搜索参数
  const [courseTypeEnum, setCourseTypeEnum] = useState([]);
  const [courseState, setCourseState] = useState('');
  const [courseType, setCourseType] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});

  useEffect(() => {
    getlist();
  }, [courseState, courseType, searchRange.endDate, searchRange.startDate, studentId]);

  const getlist = () => {
    const urlParams = `studentId=${studentId}&courseState=${courseState}&courseType=${courseType}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/student/course/order/list?${urlParams}`, 'GET' ).then(res=> {
      setOrderList(res.records)
    })
  }

  useEffect(() => {
    request(`/common/constants/all`, 'GET' ).then(res=> {
      setCourseTypeEnum(res.courseType);
    });
  }, []);

  const getDetail = (courseInstanceId, orderData) => {
    setExtraParams({
      orderId: orderData.orderId,
      userId: orderData.userId
    })
    request(`/course/courseInstance/detail?courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
      setOrderDetail(res);
      setDetailVisible(true);
    })
  }
  
  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const cancelAppoint = () => {
    const params = {
      "courseInstanceId": orderDetail.instanceId,
      "cancelType": 0, //0 单个课程 1 整个学期
      "canCancelCnt": 1, //检查接口返回的可以取消多少节
      ...extraParams
    }
    request(`/course/courseInstance/order/cancel`, 'POST', params ).then(res=> {
      Toast.show({mess: '取消预约成功'});
      setDetailVisible(false);
      getlist();
    });
  }

  const textData1 = [{
    leftText: '课程类型',
    rightText: orderDetail.courseTypeDesc
  }, {
    leftText: '课程类别',
    rightText: orderDetail.courseCategory
  }, {
    leftText: '舞蹈种类',
    rightText: orderDetail.danceCategory
  }, {
    leftText: '舞蹈/歌曲名称',
    rightText: orderDetail.danceName
  }, {
    leftText: '课程日期',
    rightText: orderDetail.courseDate
  }, {
    leftText: '课程星期',
    rightText: '星期' + orderDetail.weekDay
  }, {
    leftText: '课程价格',
    rightText: orderDetail.coursePrice + '课时'
  }, {
    leftText: '教室名称',
    rightText: orderDetail.classRoomName
  }, {
    leftText: '老师姓名',
    rightText: orderDetail.teacherName
  }, {
    leftText: '人数上限',
    rightText: orderDetail.ceilingCnt
  }];

  const textData2 = [{
    leftText: '门店名称',
    rightText: orderDetail.studioName
  }, {
    leftText: '门店地址',
    rightText: orderDetail.studioAddress
  }, {
    leftText: '适合人群',
    rightText: orderDetail.crowd
  }, {
    leftText: '取消规则',
    rightText: orderDetail.cancelTime + '小时'
  }];

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'createAt'
    },
    {
      title: '课程类型',
      dataIndex: 'courseTypeDesc'
    },
    {
      title: '课程类别',
      dataIndex: 'courseCategory'
    },
    {
      title: '舞蹈种类',
      dataIndex: 'danceCategory'
    },
    {
      title: '课程日期星期',
      dataIndex: 'weekDay',
      render: (text, row, index) => {
        return row.courseDate + ' 星期' + text
      }
    },
    {
      title: '门店名称',
      dataIndex: 'studioName'
    },
    {
      title: '老师',
      dataIndex: 'teacherName'
    },
    {
      title: '课价',
      dataIndex: 'coursePrice',
      render: (text, row, index) => {
        return text + '课时'
      }
    },
    {
      title: '课程状态',
      dataIndex: 'courseState',
      render: (text, row, index) => {
        return text;
      }
    },
    {
      title: '操作',
      dataIndex: 'courseInstanceId',
      render: (text, row, index) => {
        return <div className="lesson-list-table-opt" >
          <i className="iconfont icon-eye cursor" onClick={() => getDetail(text, row)}></i>
        </div>;
      },
    },
  ];
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  return (
    <div className="teacher-detail-page common-page-container">
      <div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">预约记录</span>
            <Select className="inline-block filter-item" placeholder="课程类型" onChange={(val) => setCourseType(val)}>
              <Option value="">全部</Option>
              {
                courseTypeEnum.map((item, index) => {
                  return <Option value={item.code} key={index}>{item.desc}</Option>
                })
              }
            </Select>
            <Select className="inline-block filter-item" placeholder="状态" onChange={(val) => setCourseState(val)}>
              <Option value="">全部</Option>
              <Option value={0}>待进行</Option>
              <Option value={1}>已进行</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
        </div>
        <Table columns={columns} dataSource={orderList} bordered 
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>

      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={true}
        visible={detailVisible}
        destroyOnClose={true}
        onClose={() => setDetailVisible(false)}
      >
        
        <img src={orderDetail.imgUrl} alt="" className="drawer-detail-img" />
        {
            textData1.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          {
            textData2.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          <GapLine />
          <div className="small-text-text">{orderDetail.title}</div>
          <div className="small-text-text">{orderDetail.content}</div> 
          <div className="draw-footer">
              <Form.Item className="form-item-flex">
                <span>{orderDetail.courseState}</span>
                <Button type="primary" onClick={cancelAppoint}>取消预约</Button>
              </Form.Item>
            </div>
      </Drawer>
    </div>
  );
}

export default StudentAppoint;
