/**
* @file: description
* @author: huguantao
* @Date: 2021-06-04 18:21:19
* @LastEditors: huguantao
* @LastEditTime: 2021-06-08 00:34:11
 */
import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { request } from '../utils/request';
import LoginImg from '../assets/img/login.png';
import '../styles/login.scss';

function Login() {
  const [pwdErrMsg, setPwdErrMsg] = useState('');
  let history = useHistory();

  const doLogin = (values) => {
    request(`/login`, 'POST', values, {}, true ).then(res=> {
      if(res.code === 2000 || res.code === 2002) {
        localStorage.setItem('userName', values.username);
        history.push(`/lesson/dashboard`);
      } else {
        setPwdErrMsg('用户名密码不匹配，请重新尝试');
      }
    })
  }

  return (
    <div className="login-page">
      <div className="login-content">
        <img className="login-content-img" src={LoginImg} alt="login" />
        <div className="login-content-form">
          <Form
            onFinish={doLogin}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: '' }]}
            >
              <Input placeholder="用户名" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
              help={pwdErrMsg}
            >
              <Input placeholder="密码" type="password" />
            </Form.Item>
            <Form.Item>
              <Button type="large" htmlType="submit">登录</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
