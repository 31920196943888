/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Drawer, Input, Select, Button, DatePicker, Upload, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';
 
import reUploadImg from '../../assets/img/reUpload.png';
import '../../styles/teacher/list.scss';
import '../../styles/drawerFormCommon.scss';
import Toast from '../../components/Toast/Toast';

let uploadImgId = '';

function TeacherList() {
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});
  const [teacherList, setTeacherList] = useState([]);
  
  const [DetailVisible, setDetailVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [teacherDetail, setTeacherDetail] = useState({});
  const [newTeacherType, setNewTeacherType] = useState('edit');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');

  const [transVisible, setTransVisible] = useState(false);
  const [transUserid, setTransUserid] = useState('');
  const statusRef = useRef();

  useEffect(() => {
    searchText.length < 1 && getTeacherList();
  }, [searchRange.endDate, searchRange.startDate, searchStatus, searchText]);

  const getTeacherList = () => {
    const urlParams = `?email=${searchText}&userStatus=${searchStatus}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/teacher/search/list${urlParams}`, 'GET' ).then(res=> {
      setTeacherList(res.records);
    })
  }

  const setUploadImgId = (id) => {
    uploadImgId = id;
  }

  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  let history = useHistory();
  const gotoDetail = (id) => {
    history.push(`/teacher/detail/${id}`);
  }
  
  const addNewTeacher = () => {
    setTeacherDetail({});
    showEditVisible();
    setUploadImgUrl('');
    setUploadImgId('');
    setNewTeacherType('new')
  }
  
  const showDetailVisible = () => {
    setDetailVisible(true);
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideDetailtVisible = () => {
    setDetailVisible(false);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
    setTeacherDetail({});
  }

  const checkTeacher = (id) => {
    request(`/teacher/detail?teacherId=${id}`, 'GET').then(res=> {
      showDetailVisible();
      setTeacherDetail(res);
      setUploadImgUrl(res.avatarUrl);
      setUploadImgId(res.avatarId)
    })
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const submitTeacher = (params) => {
    if(!uploadImgId) {
      Toast.show({ mess: '请上传老师头像'});
      return;
    }
    const submitParams = {
      userStatus: statusRef.current.input.checked ? 1 : 0,
      avatarId: uploadImgId,
      ...params
    }
    newTeacherType === 'edit' && (submitParams.userId = teacherDetail.userId);
    request(`${newTeacherType === 'new' ? '/teacher/add' : '/teacher/edit'}`, 'POST', submitParams, {}, true ).then(res=> {
      if(res.code === 4009) {
        // 弹窗提示转换成老师
        setTransUserid(res.data.userId);
        setTransVisible(true);
      } else if(res.code === 2000) {
        hideEditVisible();
        getTeacherList();
      } else {
        Toast.show({mess: res.msg})
      }
    })
  }

  const transferToTeacher = () => {
    const transSubmitData = {
      userIdentity: 2, //用户要转换的身份 1学生 2老师
      userId: transUserid
    }
    request(`/user/userIdentity/switch`, 'POST', transSubmitData).then(res=> {
      hideEditVisible();
      getTeacherList();
      setTransVisible(false)
    })
  }

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  const TeacherCard = (data) => {
    const teacher = data.teacherData;
    return <div className="teacher-card box-shadow-card">
      <div className="card-img-wrap">
        <img src={teacher.avatarUrl} className="card-img inline-block" alt="" />
        <i className="iconfont icon-dui" style={{color: teacher.userStatus === 1 ? '#2943E2' : '#CED2D5'}}></i>
      </div>
      <div className="card-title1 text-center small-text-text">{teacher.userName}</div>
      <div className="card-title2 text-center small-text-text">ID: {teacher.userId}</div>
      <GapLine />
      <JustifyText leftText="手机" rightText={teacher.mobile} />
      <JustifyText leftText="邮箱" rightText={teacher.userEmail} />
      <JustifyText leftText="创建日期" rightText={teacher.createAt} />
      <div className="card-text gray-text-desc">
        <i className="iconfont icon-eye inline-block text-center cursor" onClick={() => checkTeacher(teacher.userId)}></i>
        <Button onClick={ () => gotoDetail(teacher.userId)}>课程记录</Button>
      </div>
    </div>
  }

  return (
    <div className="teacher-list-page common-page-container">
      <div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">老师</span>
            <Input placeholder="输入后按回车键搜索" className="inline-block filter-item" onChange={e => setSearchText(e.target.value)} onPressEnter={getTeacherList}/>
            <Select className="inline-block filter-item" placeholder="状态"
              onChange={val => setSearchStatus(val)}>
              <Option value="">全部</Option>
              <Option value={1}>有效</Option>
              <Option value={0}>无效</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
          <div className="opts">
            <Button type="primary" onClick={addNewTeacher}><i className="iconfont icon-add"></i>&nbsp;创建</Button>
          </div>
        </div>
        <div className="teacher-card-container">
          {
            teacherList.map(item => {
              return <TeacherCard teacherData={item} key={item.userId}/>
            })
          }
        </div>
      </div>
        
      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={hideDetailtVisible}
        visible={DetailVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={hideDetailtVisible} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={() => {
              hideDetailtVisible();
              showEditVisible();
              setNewTeacherType('edit');
              }} 
              type="primary"
            >
              编辑
            </Button>
          </div>
        }
      >
        <img src={teacherDetail.avatarUrl} className="shopImg headImg" alt="" />
        <JustifyText leftText="老师姓名：" rightText={teacherDetail.userName} />
        <JustifyText leftText="老师邮箱：" rightText={teacherDetail.userEmail} />
        <JustifyText leftText="老师手机：" rightText={teacherDetail.mobile} />
        <GapLine />
        <JustifyText leftText="老师简介：" rightText={teacherDetail.desc} />
        <GapLine />
        <div className="form-input">
          <Input type="checkbox" checked={teacherDetail.userStatus === 1} className="form-input-checkbox unEditable-checkbox-input" />生效
        </div>
      </Drawer>
      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader headImg-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img headImg-uploader-placeholder-img" />
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
            </div>
          }
        </Upload>
        <Form
            onFinish={submitTeacher}
          >
            <Form.Item
              name="teacherName"
              rules={[{ required: true, message: '请输入老师姓名' }]}
              initialValue={teacherDetail.userName}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>老师姓名： </span>
                <Input placeholder="请填写" defaultValue={teacherDetail.userName || ''} />
              </div>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: '请输入老师邮箱' }]}
              initialValue={teacherDetail.userEmail}
            >
              <div className="form-input display-flex">
                <span className="form-input-label"><span className="red-text">*</span>老师邮箱： </span>
                <Input placeholder="请填写" defaultValue={teacherDetail.userEmail || ''} />
              </div>
            </Form.Item>
            <Form.Item name="mobile" initialValue={teacherDetail.mobile}>
              <div className="form-input display-flex">
                <span className="form-input-label">老师手机： </span>
                <Input placeholder="请填写" defaultValue={teacherDetail.mobile || ''} />
              </div>
            </Form.Item>
            <Form.Item name="description" initialValue={teacherDetail.mobile}>
              <div className="form-input display-flex">
                <span className="form-input-label">老师简介： </span>
                <TextArea placeholder="" defaultValue={teacherDetail.desc} className="admin-index-form-textarea" />
              </div>
            </Form.Item>
            <div className="form-input">
              <Input type="checkbox" ref={statusRef} defaultChecked={newTeacherType === 'new' ?true : (teacherDetail.userStatus === 1)} className="form-input-checkbox" />生效
            </div>
            <div className="draw-footer">
              <Form.Item>
                <Button onClick={hideEditVisible}>取消</Button>
                <Button htmlType="submit" type="primary">确认</Button>
              </Form.Item>
            </div>
          </Form>
      </Drawer>
      <Modal title="" visible={transVisible} className="trans-to-modal" centered
        onOk={transferToTeacher} onCancel={() => {setTransVisible(false);hideEditVisible()}}
        closable={false} maskClosable={false} width={350}>
        <p>该邮箱已被注册为</p>
        <p>学员，是否转换为老师？</p>
      </Modal>
    </div>
  );
}

export default TeacherList;
