/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:18
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:18
 */
import React, {useState, useEffect, useRef} from 'react';
import { Form, Input, Select, Button, Table, DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import Toast from '../../components/Toast/Toast';

import '../../styles/student/recharge-score-appoint.scss';

function StudentLesson(props) {
  const studentId = props.match.params.id;
  const [classTransType, setClassTransType] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});
  const [expiredDate, setExpiredDate] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [studentDetail, setStudentDetail] = useState({});

  const formRef = useRef();

  useEffect(() => {
    getTransList()
  }, [classTransType, searchRange.endDate, searchRange.startDate, studentId]);

  useEffect(() => {
    getUserDetail();
  }, [studentId])

  const getTransList = () => {
    const urlParams = `userId=${studentId}&classTransType=${classTransType}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/user/class/trans/list?${urlParams}`, 'GET' ).then(res=> {
      setOrderList(res.records)
    })
  }

  const getUserDetail = () => {
    const urlParams = `studentId=${studentId}&ext=true`;
    request(`/student/detail?${urlParams}`, 'GET' ).then(res=> {
      setStudentDetail(res)
    })
  }

  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const onCourseDateChange = (date) => {
    setExpiredDate(moment(date).format('YYYY-MM-DD'));
  }

  const handleSubmit = (params) => {
    const submitParams = {
      ...params,
      userId: studentId,
      expiredDate
    }
    Toast.show({type: 'loading'});
    request(`/user/class/trans/doTrans`, 'POST', submitParams ).then(res=> {
      Toast.hide();
      Toast.show({mess: '成功'});
      getTransList();
      getUserDetail();
      formRef.current && formRef.current.resetFields();
    })
  }

  const columns = [
    {
      title: '交易时间',
      dataIndex: 'transTime'
    },
    {
      title: '交易类型',
      dataIndex: 'classTransTypeDesc'
    },
    {
      title: '交易课时',
      dataIndex: 'changeNum'
    },
    {
      title: '备注信息',
      dataIndex: 'remark'
    }
  ];
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  return (
    <div className="student-RSA-page common-page-container">
      <div className="student-RSA-page-left">
        <div className="common-title-text">课时详情</div>
        <div className="box-shadow-card student-RSA-page-left-content">
          <div className="charge-num text-center">{studentDetail.classNum}</div>
          <div className="small-text-text text-center">剩余课时</div>
          <div className="small-text-text text-center">有效期至：{studentDetail.classExpireDate}</div>
          <GapLine />
          <div className="student-RSA-content-form">
            <Form
              onFinish={handleSubmit} ref={formRef} initialValues={{
                classTransType: '', classNum: '', expiredMonth: '', remark: ''
              }}
            >
              <div className="small-text-text">冲课/消课类型 <span style={{color: '#F96E53'}}>*</span></div>
              <Form.Item
                name="classTransType"
                rules={[{ required: true, message: '请选择课时类型' }]}
                help=""
              >
                <Select placeholder="类型" className="inline-block filter-item">
                  <Option value={0}>充课</Option>
                  <Option value={1}>消课</Option>
                </Select>
              </Form.Item>
              <div className="small-text-text">课时</div>
              <Form.Item
                name="classNum"
                // rules={[{ required: true, message: '请输入课时' }]}
                help=""
              >
                <Input placeholder="" type="number" />
              </Form.Item>
              <div className="small-text-text">有效期</div>
              <Form.Item help="">
                <DatePicker onChange={onCourseDateChange} defaultValue={null} locale={locale} />
              </Form.Item>
              <div className="small-text-text">备注</div>
              <Form.Item name="remark">
                <TextArea placeholder="" className="form-textarea" />
              </Form.Item>
              <Form.Item>
                <div className="bottom-opt">
                  <Button type="primary" htmlType="submit">确认</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="student-RSA-page-right">
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">交易记录</span>
            <Select placeholder="类型" className="inline-block filter-item" onChange={val => setClassTransType(val)}>
              <Option value={""}>全部</Option>
              <Option value={0}>充课</Option>
              <Option value={1}>消课</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
        </div>
        <Table columns={columns} dataSource={orderList} bordered 
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
    </div>
  );
}

export default StudentLesson;
