/**
* @file: description
* @author: huguantao
* @Date: 2021-06-04 18:21:19
* @LastEditors: huguantao
* @LastEditTime: 2021-06-08 00:34:11
 */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Drawer, Button, Upload, Form, Input, Tabs, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';

import reUploadImg from '../../assets/img/reUpload.png';
import '../../styles/admin/index.scss';
import '../../styles/drawerFormCommon.scss';

let uploadImgId = ''; // usestate更新不及时，改成变量
let teacherId = '';
let studioId = '';
let classRoomId = '';

function AdminIndex() {
  const [pointDetail, setPointDetail] = useState({title: ''});
  const [lessonList, setLessonList] = useState([]);

  // 枚举
  const [allStudio, setAllStudio] = useState([]);
  const [allClassroom, setAllClassroom] = useState([]);
  const [allTeachers, setAllTeachers] = useState([])

  // 私教课编辑相关
  const [lessonDetailVisible, setLessonDetailVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [lessonDetail, setLessonDetail] = useState({});
  const [newLessonType, setNewLessonType] = useState('edit');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');
  // const [studioId, setStudioId] = useState();
  // const [classRoomId, setClassRoomId] = useState();
  // const [teacherId, setTeacherId] = useState();
  // 私教课编辑相关

  const pointDetailFormRef = useRef(); // 积分兑换表单
  const lessonDetailFormRef = useRef(); // 积分兑换表单
  const statusRef = useRef(); // 课程生效状态

  const setUploadImgId = (id) =>{
    uploadImgId = id
  }

  const setStudioId = (val) => {
    studioId = val;
    if(val == '') {
      setAllClassroom([]);
      return
    }
    getAllClassRoom(studioId);
  }
  
  const setClassRoomId = (val) => {
    classRoomId = val
  }
  
  const setTeacherId = (val) => {
    teacherId = val
  }

  useEffect(() => {
    getPointDetail();
    getLessonList();
    request(`/studio/all?studioStatus=1`, 'GET' ).then(res=> {
      setAllStudio(res);
    });
  }, []);

  // 门店的选择引起教室的联动
  useEffect(() => {
    getAllClassRoom(studioId);
    getAllTeachers();
  }, []);

  const getPointDetail = () => {
    request(`/config/score/detail`, 'GET' ).then(res=> {
      setPointDetail(res);
      setTimeout(() => {
        pointDetailFormRef.current && pointDetailFormRef.current.resetFields();
      });
    })
  }

  const getLessonList = () => {
    request(`/course/private/list`, 'GET' ).then(res=> {
      setLessonList(res.records);
    })
  }

  const pointsSubmit = (values) => {
    request(`/config/score/save`, 'POST', values).then(res=> {
      getPointDetail();
    });
  }
  
  const showDetailVisible = () => {
    setLessonDetailVisible(true);
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideDetailtVisible = () => {
    setLessonDetailVisible(false);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
    setLessonDetail({});
  }

  const checkLesson = (id) => {
    getLessonDetail(id);
    
    setNewLessonType('edit')
  }

  const getLessonDetail = (id) => {
    request(`/course/private/detail?courseId=${id}`, 'GET', {}, {} ).then(res=> {
      showDetailVisible();
      setLessonDetail(res);
      setUploadImgUrl(res.imgUrl);
      setUploadImgId(res.imgId);

      setStudioId(res.studioId);
      setClassRoomId(res.classRoomId);
      setTeacherId(res.teacherId);

      getAllClassRoom(res.studioId);
      
      // lessonDetailFormRef.current && lessonDetailFormRef.current.resetFields();
    });
  }
  const getAllClassRoom = (id) => {
    if(id) {
      request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
        setAllClassroom(res);
        setClassRoomId(res[0].classRoomId);
      });
    }
  }
  const getAllTeachers = () => {
    request(`/teacher/search`, 'GET' ).then(res=> {
      setAllTeachers(res.records);
    });
  }

  const addNewLesson = () => {
    setLessonDetail({});
    showEditVisible();
    setUploadImgUrl();
    setUploadImgId('');
    setStudioId('');
    setClassRoomId('');
    setTeacherId('');
    setNewLessonType('new');
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const submitLesson = (params) => {
    const submitParams = {
      courseId: lessonDetail.courseId,
      status: statusRef.current.input.checked ? 1 : 0,
      imgId: uploadImgId,
      studioId: studioId,
      classRoomId: classRoomId,
      teacherId: teacherId,
      ...params
    }
    request(`${newLessonType === 'new' ? '/course/private/add' : '/course/private/edit'}`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      getLessonList();
    })
  }

  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const { Option } = Select;
  const LessonCard = (data) => {
    const lessonData = data.lessonData;
    return <div className="add-shop-card cursor" onClick={() => checkLesson(lessonData.courseId)}>
      <img src={lessonData?.imgUrl} className="card-img" alt="shop" />
      <div className="card-title small-title-text">{lessonData?.danceName}</div>
      <div className="card-text gray-text-desc">
        <img src={lessonData?.teacherAvatarUrl} alt="" />
        <div className="catd-text-desc">
          <p>{lessonData?.courseCategory}{lessonData?.teacherName? ' | ' : ''}{lessonData?.teacherName}</p>
          <p>{lessonData?.studioName}</p>
        </div>
      </div>
    </div>
  }

  const pointFrom = () => {
    return <Form
      onFinish={pointsSubmit}
      initialValues={pointDetail}
      ref={pointDetailFormRef}
    >
      <div className="small-text-text">标题</div>
      <Form.Item
        name="title"
        className="admin-index-form-input1"
        rules={[{ required: true, message: '请输入标题' }]}
      >
        <Input />
      </Form.Item>
      <div className="small-text-text">正文</div>
      <Form.Item
        name="content"
        rules={[{ required: true, message: '请输入正文' }]}
      >
        <TextArea placeholder="" className="admin-index-form-input2"  />
      </Form.Item>
      <div className="small-text-text">兑换说明</div>
      <Form.Item
        name="desc"
        rules={[{ required: true, message: '请输入兑换说明' }]}
      >
        <TextArea placeholder="" className="admin-index-form-input3" />
      </Form.Item>
      <Form.Item style={{textAlign: 'right'}}>
        <Button type="primary" htmlType="submit">确认</Button>
      </Form.Item>
    </Form>
  };

  const lessonForm = useCallback(() => {
    return <Form onFinish={submitLesson} ref={lessonDetailFormRef} initialValues={lessonDetail}>
      <Form.Item name="courseCategory" rules={[{ required: true, message: '请填写课程类别' }]} >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程类别： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.courseCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceCategory" rules={[{ required: true, message: '请填写舞蹈种类' }]} >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>舞蹈种类： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.danceCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceName">
        <div className="form-input display-flex">
          <span className="form-input-label">舞蹈/歌曲名称： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.danceName} />
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">门店名称： </span>
          <Select onSelect={(val) => setStudioId(val)} defaultValue={lessonDetail.studioId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allStudio.map((item, index) => {
                return <Option value={item.studioId} key={index}>{item.studioName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      {/* 门店和其地址是绑定的，只需先择一个 */}
      {/* <Form.Item name="address">
        <div className="form-input display-flex">
          <span className="form-input-label">门店地址： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.address} />
        </div>
      </Form.Item> */}
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">教室名称： </span>
          <Select onChange={(val) => setClassRoomId(val)} defaultValue={lessonDetail.classRoomId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allClassroom.map((item, index) => {
                return <Option value={item.classRoomId} key={index}>{item.classRoomName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">老师姓名： </span>
          <Select onChange={(val) => setTeacherId(val)} defaultValue={lessonDetail.teacherId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allTeachers.map((item, index) => {
                return <Option value={item.teacherId} key={index}>{item.teacherName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="ceilingCnt">
        <div className="form-input display-flex">
          <span className="form-input-label">人数上限： </span>
          <Input placeholder="请填写" type="number" defaultValue={lessonDetail.ceilingCnt} />
        </div>
      </Form.Item>
      <Form.Item name="crowd">
        <div className="form-input display-flex">
          <span className="form-input-label">适合人群： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.crowd} />
        </div>
      </Form.Item>
      <Form.Item name="title">
        <div className="form-input display-flex">
          <span className="form-input-label">简介标题： </span>
          <Input placeholder="请填写" defaultValue={lessonDetail.title} />
        </div>
      </Form.Item>
      <Form.Item name="content">
        <div className="form-input display-flex">
          <span className="form-input-label">正文编辑： </span>
          {/* <Input placeholder="请填写" defaultValue={lessonDetail.content} /> */}
          <TextArea placeholder="请填写" defaultValue={lessonDetail.content} className="form-textarea" />
        </div>
      </Form.Item>
      <div className="form-input">
        <Input type="checkbox" ref={statusRef} defaultChecked={newLessonType === 'new' ? true : (lessonDetail.courseStatus === 1)} className="form-input-checkbox" />生效
      </div>
      <div className="draw-footer">
        <Form.Item>
          <Button onClick={hideEditVisible}>取消</Button>
          <Button htmlType="submit" type="primary">确认</Button>
        </Form.Item>
      </div>
    </Form>
  }, [allStudio, allClassroom, allTeachers, lessonDetail])

  return (
    <div className="admin-index-page common-page-container">
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <TabPane tab="积分兑换" key="1">
          <div className="admin-index-form">
              {pointFrom()}
          </div>
        </TabPane>
        <TabPane tab="私教课程" key="2">
          <div className="shop-card-container">
            {
              lessonList.map(item => <LessonCard lessonData={item} key={item.courseId} />)
            }
            <div className="add-shop-card" onClick={addNewLesson}>
              <div className="add-shop-card-content">
                <span className="add-icon text-center cursor">+</span>
                <span className="gray-text-desc">添加课程</span>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={hideDetailtVisible}
        visible={lessonDetailVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={hideDetailtVisible} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={() => {
              hideDetailtVisible();
              showEditVisible();
              setNewLessonType('edit')
              }} 
              type="primary"
            >
              编辑
            </Button>
          </div>
        }
      >
        <div class="shopImg-wrap">
          <img src={lessonDetail.imgUrl} className="shopImg" alt="head" />
        </div>
        <JustifyText leftText="课程类别：" rightText={lessonDetail.courseCategory} />
        <JustifyText leftText="舞蹈种类：" rightText={lessonDetail.danceCategory} />
        <JustifyText leftText="舞蹈/歌曲名称："  rightText={lessonDetail.danceName} />
        <JustifyText leftText="门店名称：" rightText={lessonDetail.studioName} />
        <JustifyText leftText="门店地址：" rightText={lessonDetail.studioAddress} />
        <JustifyText leftText="教室名称：" rightText={lessonDetail.classRoomName} />
        <JustifyText leftText="老师姓名：" rightText={lessonDetail.teacherName} />
        <JustifyText leftText="人数上限：" rightText={lessonDetail.ceilingCnt} />
        <JustifyText leftText="适合人群：" rightText={lessonDetail.crowd} />
        <GapLine />
        <div className="small-text-text">{lessonDetail.title}</div>
        <div className="small-text-text">{lessonDetail.content}</div>
        <div className="form-input">
          <Input type="checkbox" checked={lessonDetail.courseStatus === 1} className="form-input-checkbox unEditable-checkbox-input" />生效
        </div>
      </Drawer>
      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <div class="placeholder-img-wrap">
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
              </div>
              {/* <img src={uploadImgUrl} alt="avatar" className="ant-upload-placeholder-img" /> */}
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
              <div style={{ marginTop: 8 }}>上传课程图片</div>
            </div>
          }
        </Upload>
        {lessonForm()}
      </Drawer>
    </div>
  );
}

export default AdminIndex;
