/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:41:29
* @LastEditors: huguantao
* @LastEditTime: 2021-06-07 23:49:03
 */
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import Lgoo from '../../assets/img/logo.png';
import ImgLesson from '../../assets/img/menu/lesson.png';
import ImgLessonS from '../../assets/img/menu/lesson-s.png';
import ImgTeacher from '../../assets/img/menu/teacher.png';
import ImgTeacherS from '../../assets/img/menu/teacher-s.png';
import ImgStudent from '../../assets/img/menu/student.png';
import ImgStudentS from '../../assets/img/menu/student-s.png';
import ImgEdit from '../../assets/img/menu/edit.png';
import ImgEditS from '../../assets/img/menu/edit-s.png';
import ImgShop from '../../assets/img/menu/shop.png';
import ImgShopS from '../../assets/img/menu/shop-s.png';
import './index.scss';

function Nav() {
  const [selectTab, setSelectTab] = useState(1); // 当前路由选中的是哪个

  useEffect(() => {
    const path = window.location.pathname;
    changeTab(path);
  }, []);

  let history = useHistory();
  const onChange = (value) => {
    history.push(`/${value}`);
    changeTab(`/${value}`);
  }

  const changeTab = (path) => {
    if(path.includes('/lesson')) {
      setSelectTab(1);
    } else if(path.includes('/teacher')) {
      setSelectTab(2);
    } else if(path.includes('/student')) {
      setSelectTab(3);
    } else if(path.includes('/admin')) {
      setSelectTab(4);
    } else if(path.includes('/shop')) {
      setSelectTab(5);
    }
  }

  return (
    <div className="nav-component">
      <div className="nav-component-content">
        <img src={Lgoo} className="nav-component-logo" alt="logo" />
        <div className="nav-component-tabs">
          <span className={`nav-component-tab cursor ${selectTab === 1 ? 'nav-component-tab-selected' : ''}`} onClick={() => onChange('lesson/dashboard')}>
            <img src={selectTab === 1 ? ImgLessonS : ImgLesson} className="nav-component-tab-img" alt="lesson" />
          </span>
          <span className={`nav-component-tab cursor ${selectTab === 2 ? 'nav-component-tab-selected' : ''}`} onClick={() => onChange('teacher/list')}>
            <img src={selectTab === 2 ? ImgTeacherS : ImgTeacher} className="nav-component-tab-img" alt="teacher" />
          </span>
          <span className={`nav-component-tab cursor ${selectTab === 3 ? 'nav-component-tab-selected' : ''}`} onClick={() => onChange('student/list')}>
            <img src={selectTab === 3 ? ImgStudentS : ImgStudent} className="nav-component-tab-img" alt="student" />
          </span>
          <span className={`nav-component-tab cursor ${selectTab === 4 ? 'nav-component-tab-selected' : ''}`} onClick={() => onChange('admin')}>
            <img src={selectTab === 4 ? ImgEditS : ImgEdit} className="nav-component-tab-img" alt="edit" />
          </span>
          <span className={`nav-component-tab cursor ${selectTab === 5 ? 'nav-component-tab-selected' : ''}`} onClick={() => onChange('shop')}>
            <img src={selectTab === 5 ? ImgShopS : ImgShop} className="nav-component-tab-img" alt="shop" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Nav;