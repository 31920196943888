/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Select, Button, Table, DatePicker, Drawer, Upload, TimePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64, getdayFromNum } from '../../utils/helper';
import Toast from '../../components/Toast/Toast';

import reUploadImg from '../../assets/img/reUpload.png';
import editIcon from '../../assets/img/icon-edit.png';
import '../../styles/lesson/list.scss';

// IMP: usestate在select组件中不更新了，偷懒写进全局来用。 每次编辑和新建，都要初始化他们
let courseType = '';
let studioId = '';
let classRoomId = '';
let teacherId = '';
let newCourseType = 'edit';
let timeRange = ['00:00', '00:00'];
let courseCycle = [];
let uploadImgId = '';
let dateRange = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD')
}

function LessonList() {
  const [courseTypeEnum, setCourseTypeEnum] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});
  const [courseList, setCourseList] = useState([]);

  // 枚举
  const [allStudio, setAllStudio] = useState([]);
  const [allClassroom, setAllClassroom] = useState([]);
  const [allTeachers, setAllTeachers] = useState([])

  // 编辑课程
  const [DetailVisible, setDetailVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [courseDetail, setCourseDetail] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');
  // const [newCourseType, setNewCourseType] = useState('edit');
  // const [courseType, setCourseType] = useState('')
  // const [studioId, setStudioId] = useState();
  // const [classRoomId, setClassRoomId] = useState();
  // const [teacherId, setTeacherId] = useState();
  // const [timeRange, setTimeRange] = useState(['00:00', '00:00']);
  // const [courseCycle, setCourseCycle] = useState([]);
  // const [dateRange, setDateRange] = useState({
  //   startDate: moment().format('YYYY-MM-DD'),
  //   endDate: moment().format('YYYY-MM-DD')
  // });

  const statusRef = useRef();
  const courseDetailFormRef = useRef(); // 表单实例
  
  let history = useHistory();

  const setUploadImgId = (id) => {
    uploadImgId = id;
  }

  const setSelectValueByKey = (key, val) => {
    switch(key) {
      case 'newCourseType':
        newCourseType = val;
        break;
      case 'courseType':
        courseType = val;
        break;
      case 'studioId':
        studioId = val;
        if(val == '') {
          setAllClassroom([]);
          return
        }
        // 门店的选择引起教室的联动
        getAllClassRoom(val);
        break;
      case 'classRoomId':
        classRoomId = val;
        break;
      case 'teacherId':
        teacherId = val;
        break;
      case 'timeRange': 
        timeRange = val;
        break;
      case 'courseCycle':
        courseCycle = val;
        break;
      default: 
        break
    }
  }

  const setDateRange = (val) => {
    dateRange = val;
  }

  useEffect(() => {
    request(`/common/constants/all`, 'GET' ).then(res=> {
      setCourseTypeEnum(res.courseType);
    });
    request(`/studio/all?studioStatus=1`, 'GET' ).then(res=> {
      setAllStudio(res);
    });
    
    getAllTeachers();
  }, []);

  useEffect(() => {
    getCourseList();
  }, [searchRange.endDate, searchRange.startDate, searchStatus, searchType]);

  const getCourseList = () => {
    const urlParams = `?courseType=${searchType}&instanceStatus=${searchStatus}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/course/list${urlParams}`, 'GET' ).then(res=> {
      setCourseList(res.records);
    })
  }

  const onDateChange = (val) => {
    setSearchRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const gotoDetail = (id) => {
    history.push(`/lesson/list/item/${id}`);
  }
  
  const addNewCourse = () => {
    showEditVisible();
    setCourseDetail({});
    setUploadImgUrl('');
    setUploadImgId('');
    setSelectValueByKey('newCourseType','new');
    setSelectValueByKey('courseType','');
    setSelectValueByKey('studioId','');
    setSelectValueByKey('classRoomId','');
    setSelectValueByKey('teacherId','');
    setSelectValueByKey('timeRange', ['00:00', '00:00']);
    setSelectValueByKey('courseCycle',[]);
  }
  
  const showDetailVisible = () => {
    setDetailVisible(true);
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }

  const hideDetailtVisible = () => {
    setDetailVisible(false);
  }

  const hideEditVisible = () => {
    setEditVisible(false);
    setCourseDetail({});
  }

  const checkCourse = (id) => {
    request(`/course/detail?courseId=${id}`, 'GET').then(res=> {
      showDetailVisible();
      setCourseDetail(res);
      setUploadImgUrl(res.imgUrl);
      setUploadImgId(res.imgId);
      setSelectValueByKey('courseType', res.courseType);
      setSelectValueByKey('studioId', res.studioId);
      setSelectValueByKey('classRoomId', res.classRoomId);
      setSelectValueByKey('teacherId', res.teacherId);
      setSelectValueByKey('timeRange', [res.startTime, res.endTime]);
      setSelectValueByKey('courseCycle', res.courseCycle);

      getAllClassRoom(res.studioId);

      // courseDetailFormRef.current && courseDetailFormRef.current.resetFields();
    })
  }
  
  const getAllClassRoom = (id) => {
    if(id) {
      request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
        setAllClassroom(res);
      });
    }
  }
  const getAllTeachers = () => {
    request(`/teacher/search`, 'GET' ).then(res=> {
      setAllTeachers(res.records);
    }); 
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const onTimeRangeChange = (val) => {
    console.log(moment(val[0]).format('HH:mm'))
    setSelectValueByKey('timeRange', [moment(val[0]).format('HH:mm'), moment(val[1]).format('HH:mm')]);
  }
  const onDateRangeChange = (val) => {
    setDateRange({
      startDate: moment(val[0]).format('YYYY-MM-DD'),
      endDate: moment(val[1]).format('YYYY-MM-DD')
    })
  }

  const submitCourse = (params) => {
    let submitParams = {
      courseId: courseDetail.courseId,
      courseType,
      studioId,
      classRoomId,
      teacherId,
      imgId: uploadImgId,
      startTime: timeRange[0],
      endTime: timeRange[1],
      instanceStatus: statusRef.current.input.checked ? 1 : 0,
      ...params
    }
    if(newCourseType === 'new') {
      submitParams = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        courseCycle,
        ...submitParams
      }
    }
    newCourseType === 'edit' && (submitParams.courseId = courseDetail.courseId);
    request(`${newCourseType === 'new' ? '/course/create' : '/course/edit'}`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      Toast.show({mess: newCourseType === 'edit' ? '编辑成功' : '创建成功'});
      setSelectValueByKey('teacherId','');
      getCourseList();
    })
  }

  const gotoAll = () => {
    history.push('/lesson/list/item/all');
  }

  const textData1 = [{
    leftText: '课程类型',
    rightText: courseDetail.courseTypeDesc
  }, {
    leftText: '课程类别',
    rightText: courseDetail.courseCategory
  }, {
    leftText: '舞蹈种类',
    rightText: courseDetail.danceCategory
  }, {
    leftText: '舞蹈/歌曲名称',
    rightText: courseDetail.danceName
  }, {
    leftText: '日期区间',
    rightText: courseDetail.startDate + '~' + courseDetail.endDate
  }, {
    leftText: '课程时间',
    rightText: courseDetail.startTime + '-' + courseDetail.endTime
  }, {
    leftText: '课程周期',
    rightText: courseDetail?.courseCycle ? '周' + getdayFromNum(courseDetail?.courseCycle).join('、') : ''
  }, {
    leftText: '课程价格',
    rightText: courseDetail.coursePrice + '课时'
  }, {
    leftText: courseDetail.courseType == 1 ? '学期课数' : null,
    rightText: courseDetail.courseType == 1 ? courseDetail.courseInstanceCnt : null
  }, {
    leftText: '门店名称',
    rightText: courseDetail.studioName
  }, {
    leftText: '门店地址',
    rightText: courseDetail.studioAddress
  }, {
    leftText: '教室名称',
    rightText: courseDetail.classRoomName
  }, {
    leftText: '老师姓名',
    rightText: courseDetail.teacherName
  }, {
    leftText: '人数上限',
    rightText: courseDetail.ceilingCnt
  }, {
    leftText: '适合人群',
    rightText: courseDetail.crowd
  }, {
    leftText: '取消规则',
    rightText: courseDetail.cancelTime + '小时'
  }];

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'createAt'
    },
    {
      title: '课程类型',
      dataIndex: 'courseTypeDesc'
    },
    {
      title: '课程类别',
      dataIndex: 'courseCategory'
    },
    {
      title: '舞蹈种类',
      dataIndex: 'danceCategory'
    },
    {
      title: '周期',
      dataIndex: 'courseCycle',
      render: function(text, row, index) {
        return '周' + getdayFromNum(text).join('、')
      }
    },
    {
      title: '门店名称',
      dataIndex: 'studioName'
    },
    {
      title: '课价',
      dataIndex: 'coursePrice',
      render: function(text, row, index) {
        return text + '课时'
      } 
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text, row, index) => {
        return <i className="iconfont icon-dui"></i>
      }
    },
    {
      title: '操作',
      dataIndex: 'courseId',
      render: (text, row, index) => {
        return <div className="lesson-list-table-opt">
          <i className="iconfont icon-write-png cursor" onClick={() => checkCourse(text)}>
            <img src={editIcon} alt="" />
          </i>
          <i className="iconfont icon-eye cursor" onClick={() => gotoDetail(text)}></i>
        </div>;
      },
    },
  ];
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  
  const courseForm = useCallback(() => {
    return <Form onFinish={submitCourse} ref={courseDetailFormRef} initialValues={courseDetail}>
      <Form.Item>
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程类型： </span>
          <Select key="courseType" onChange={(val) => setSelectValueByKey('courseType',val)} 
            defaultValue={courseDetail.courseType} className="inline-block filter-item" 
            rules={[{ required: true, message: '请选择课程类型' }]}>
            {
              courseTypeEnum.map((item, index) => {
                return <Option value={item.code} key={index}>{item.desc}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="courseCategory" rules={[{ required: true, message: '请填写课程类别' }]} >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程类别： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.courseCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceCategory">
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>舞蹈种类： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.danceCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceName">
        <div className="form-input display-flex">
          <span className="form-input-label">舞蹈/歌曲名称： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.danceName} />
        </div>
      </Form.Item>

      {/* 日期不可编辑 */}
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>日期区间： </span>
          <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              defaultValue={[moment(courseDetail.startDate), moment(courseDetail.endDate)]}
              disabled={newCourseType === 'edit' ? true : false}
              onChange={onDateRangeChange}
            />
        </div>
      </Form.Item>
      <Form.Item name="" >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程时间： </span>
          <TimePicker.RangePicker locale={locale} 
            defaultValue={[moment(courseDetail.startTime || '00:00:00', 'HH:mm:ss'), moment(courseDetail.endTime || '00:00:00', 'HH:mm:ss')]} 
            onChange={onTimeRangeChange} />
        </div>
      </Form.Item>
      <Form.Item name="" >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程周期： </span>
          {
            newCourseType === 'new' ? <Select key="time" mode="multiple" onChange={(val) => setSelectValueByKey('courseCycle', val)} defaultValue={courseDetail.courseType} className="inline-block filter-item" >
              {
                [1,2,3,4,5,6,7].map((item) => {
                return <Option value={item} key={item}>星期{item}</Option>
              })
            }
            </Select> : <Input placeholder="" defaultValue={'星期' + (courseDetail.courseCycle || []).join(',')} disabled />
          }
        </div>
      </Form.Item>

      {/* 课程价格不可编辑 */}
      <Form.Item name="coursePrice">
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程价格： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.coursePrice} disabled={newCourseType === 'edit' ? true : false} />
        </div>
      </Form.Item>

      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">门店名称： </span>
          <Select key="studio" onSelect={(val) => setSelectValueByKey('studioId',val)} defaultValue={courseDetail.studioId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allStudio.map((item, index) => {
                return <Option value={item.studioId} key={index}>{item.studioName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">教室名称： </span>
          <Select key="classroom" onSelect={(val) => setSelectValueByKey('classRoomId',val)} defaultValue={courseDetail.classRoomId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allClassroom.map((item, index) => {
                return <Option value={item.classRoomId} key={index}>{item.classRoomName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">老师姓名： </span>
          <Select onSelect={(val) => setSelectValueByKey('teacherId',val)} defaultValue={courseDetail.teacherId} className="inline-block filter-item">
            <Option value={''}>无</Option>
            {
              allTeachers.map((item, index) => {
                return <Option value={item.teacherId} key={index}>{item.teacherName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="ceilingCnt">
        <div className="form-input display-flex">
          <span className="form-input-label">人数上限： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.ceilingCnt} />
        </div>
      </Form.Item>
      <Form.Item name="crowd">
        <div className="form-input display-flex">
          <span className="form-input-label">适合人群： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.crowd} />
        </div>
      </Form.Item>
      <Form.Item name="cancelTime">
        <div className="form-input display-flex">
          <span className="form-input-label">取消时间： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.cancelTime} />
        </div>
      </Form.Item>
      <Form.Item name="title">
        <div className="form-input display-flex">
          <span className="form-input-label">简介标题： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.title} />
        </div>
      </Form.Item>
      <Form.Item name="content">
        <div className="form-input display-flex">
          <span className="form-input-label">正文编辑： </span>
          {/* <Input placeholder="请填写" defaultValue={courseDetail.content} /> */}
          <TextArea placeholder="请填写" defaultValue={courseDetail.content} className="form-textarea" />
        </div>
      </Form.Item>
      <div className="form-input">
        <Input type="checkbox" ref={statusRef} defaultChecked={newCourseType === 'new' ? true : (courseDetail.instanceStatus === 1)} className="form-input-checkbox" />生效
      </div>
      <div className="draw-footer">
        <Form.Item>
          <Button onClick={hideEditVisible}>取消</Button>
          <Button htmlType="submit" type="primary">确认</Button>
        </Form.Item>
      </div>
    </Form>
  }, [courseDetail, courseTypeEnum, allStudio, allClassroom, allTeachers])

  return (
    <div className="lesson-list-page common-page-container">
      <div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">创建记录</span>
            <Select placeholder="课程类型" className="inline-block filter-item" onChange={val => { setSearchType(val)}}>
              <Option value="">全部类型</Option>
              {
                courseTypeEnum.map(item => {
                  return <Option value={item.code} key={item.code}>{item.desc}</Option>
                })
              }
            </Select>
            <Select placeholder="生效状态" value={searchStatus} className="inline-block filter-item" onChange={val => { setSearchStatus(val)}}>
              <Option value="">全部</Option>
              <Option value={1}>有效</Option>
              <Option value={0}>未生效</Option>
            </Select>
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
          <div className="opts">
            <Button type="" className="lesson-detail-page-right-yuyue-btn" onClick={gotoAll}>课程列表</Button>
            <Button type="primary" onClick={addNewCourse}><i className="iconfont icon-add"></i>创建</Button>
          </div>
        </div>
        <Table columns={columns} dataSource={courseList} bordered 
          rowClassName={function(record, index) {return record.instanceStatus === 1 ? '' : 'ant-table-gray-row'}}
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
        
      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={hideDetailtVisible}
        visible={DetailVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={hideDetailtVisible} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={() => {
              setSelectValueByKey('newCourseType','edit');
              hideDetailtVisible();
              showEditVisible();
              }} 
              type="primary"
            >编辑</Button>
          </div>
        }
      >
        <div class="shopImg-wrap">
          <img src={courseDetail.imgUrl} className="shopImg" alt="" />
        </div>
        {
          textData1.map((item, index) => {
            return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
          })
        }
        <GapLine />
        <div className="small-text-text">{courseDetail.title}</div>
        <div className="small-text-text">{courseDetail.content}</div>
        <div className="form-input">
          <Input type="checkbox" checked={courseDetail.instanceStatus === 1 ? true : false} className="form-input-checkbox unEditable-checkbox-input" />生效
        </div>
      </Drawer>
      
      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <div class="placeholder-img-wrap">
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
              </div>
              {/* <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" /> */}
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
              <div style={{ marginTop: 8 }}>上传课程图片</div>
            </div>
          }
        </Upload>
        {courseForm()}
      </Drawer>
    </div>
  );
}

export default LessonList;
