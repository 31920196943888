/**
* @file: description
* @author: huguantao
* @Date: 2020-02-04 15:05:53
* @LastEditors: huguantao
* @LastEditTime: 2020-04-10 22:19:08
 */
import axios from 'axios';
import Toast from '../components/Toast/Toast';
// import {urlPrefix} from '../utils/constants';

/**
 * 
 * @param {]} url 
 * @param {*} method 
 * @param {*} data 
 * @param {*} headers 
 * @param {*} noHide 是否吃掉错误信息
 * @returns 
 */
export function request(url, method, data={}, headers={}, noHide=false) {
    // !noHide && Toast.show({type:'loading'});
    return new Promise((resolve,reject)=>{
        axios({
            method: method,
            // url: urlPrefix + url,
            url: '/api/v1/admin' + url,
            data: data,
            headers: headers
        }).then(res=> {
            // Toast.hide();
            if (noHide) {
                resolve(res.data);
            } else {
                if (res.data.code === 2000) {
                    resolve(res.data.data);
                } else if(res.data.code === 4004 || res.data.code === 4005 || res.data.code === 4008) {
                    Toast.show({mess: res.data.msg});
                    window.location.href = '/login';
                    reject();
                } else if (res.data.code === 4009) {
                    resolve(res.data);
                } else {
                    Toast.show({mess: res.data.msg})
                }
                reject();
            }
        }).catch(error=> {
            console.log(error)
            Toast.hide();
            // 错误处理 http://www.axios-js.com/zh-cn/docs/#%E9%94%99%E8%AF%AF%E5%A4%84%E7%90%86
            if (error.response) {
                Toast.show({mess: error.response.statusText || 'something went wrong, please try again later'});
                console.log(error.response.data);
            }
        })
    });
}
