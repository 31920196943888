/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:13
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:14
 */
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Table, DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { request } from '../../utils/request';

import '../../styles/teacher/detail.scss';

function LessonListItem(props) {
  let courseId = props.match.params.id;
  if(courseId === 'all') {
    courseId = '';
  }
  
  const [courseTypeEnum, setCourseTypeEnum] = useState([]);
  const [allStudio, setAllStudio] = useState([]);
  const [allClassroom, setAllClassroom] = useState([]);
  const [searchStudioId, setSearchStudioId] = useState('');
  const [searchClassRoomId, setSearchClassRoomId] = useState('');
  const [searchCourseType, setSearchCourseType] = useState('');

  const [leesonList, setLessonList] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRange, setSearchRange] = useState({startDate: '', endDate: ''});

  let history = useHistory();

  useEffect(() => {
    const urlParams = `&studioId=${searchStudioId}&classRoomId=${searchClassRoomId}&courseType=${searchCourseType}&instanceStatus=${searchStatus}&startDate=${searchRange.startDate}&endDate=${searchRange.endDate}`;
    request(`/course/courseInstance/list?courseId=${courseId}${urlParams}`, 'GET' ).then(res=> {
      setLessonList(res.records)
    })
  }, [searchRange.endDate, searchRange.startDate, searchStatus, courseId, searchClassRoomId, searchCourseType]);

  useEffect(() => {
    request("/studio/all?studioStatus=1", 'GET').then(res=> {
      setAllStudio(res);
      // setSearchStudioId(res[0].studioId);
      request(`/studio/classRoom/search?studioId=${res[0].studioId}`, 'GET').then(resp=> {
        setAllClassroom(resp);
        // setSearchClassRoomId(resp[0].classRoomId);
      });
    });
  }, []);

  useEffect(() => {
    request(`/common/constants/all`, 'GET' ).then(res=> {
      setCourseTypeEnum(res.courseType);
    });
  }, []);

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'createAt'
    },
    {
      title: '课程类型',
      dataIndex: 'courseTypeDesc'
    },
    {
      title: '课程类别',
      dataIndex: 'courseCategory'
    },
    {
      title: '舞蹈种类',
      dataIndex: 'danceCategory'
    },
    {
      title: '课程日期星期',
      dataIndex: 'weekDay',
      render: (text, row, index) => {
        return `${row.courseDate} 周${row.weekDay}`
      }
    },
    {
      title: '门店名称',
      dataIndex: 'studioName'
    },
    {
      title: '老师',
      dataIndex: 'teacherName'
    },
    {
      title: '课价',
      dataIndex: 'coursePrice'
    },
    {
      title: '状态',
      dataIndex: 'instanceStatus',
      render: (text, row, index) => {
        return <i className={`iconfont icon-dui`}></i>
      }
    },
    {
      title: '操作',
      dataIndex: 'courseInstanceId',
      render: (text, row, index) => {
        return <div className="lesson-list-table-opt">
          <i className="iconfont icon-eye cursor" onClick={() => gotoDetail(text)}></i>
        </div>;
      },
    },
  ];

  const handleSearchStudioId = (studioId) => {
    setSearchStudioId(studioId);
    if(studioId) {
      getAllClassRoom(studioId)
    } else {
      setSearchClassRoomId('');
    }
  }

  const getAllClassRoom = (id) => {
    request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
      setAllClassroom(res);
      setSearchClassRoomId(res[0].classRoomId);
    });
  }

  const onDateChange = (val) => {
    if(val) {
      setSearchRange({
        startDate: moment(val[0]).format('YYYY-MM-DD'),
        endDate: moment(val[1]).format('YYYY-MM-DD')
      })
    } else {
      setSearchRange({startDate: '', endDate: ''})
    }
  }

  const gotoDetail = (id) => {
    // 课程详情使用 courseInstanceId
    history.push(`/lesson/detail/${id}`);
  }
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  return (
    <div className="teacher-detail-page common-page-container">
      <div>
        <div className="common-title-text">
          <div className="filters">
            <span className="first-child">课程列表</span>
            <Select placeholder="门店" className="inline-block filter-item" value={searchStudioId}
              onChange={val => handleSearchStudioId(val)}  
            >
              <Option value={''}>所有门店</Option>
              {
                allStudio.map(item => {
                  return <Option value={item.studioId} key={item.studioId}>{item.studioName}</Option>
                })
              }
            </Select>
            <Select placeholder="教室" className="inline-block filter-item" value={searchClassRoomId}
              onChange={val => {setSearchClassRoomId(val);}}
            >
              <Option value={''}>所有教室</Option>
              {
                allClassroom.map(item => {
                  return <Option value={item.classRoomId} key={item.classRoomId}>{item.classRoomName}</Option>
                })
              }
            </Select>
            <Select className="inline-block filter-item" placeholder="状态" defaultValue={searchStatus}
              onChange={val => setSearchStatus(val)}>
              <Option value="">全部</Option>
              <Option value={1}>有效</Option>
              <Option value={0}>无效</Option>
            </Select>
            
            <Select placeholder="全部课程类型" className="inline-block filter-item" onChange={(val) => setSearchCourseType(val)} >
              <Option value="">全部</Option>
              {
                courseTypeEnum.map((item, index) => {
                  return <Option value={item.code} key={index}>{item.desc}</Option>
                })
              }
            </Select>
            
            <RangePicker
              className="filter-item filter-item-date"
              locale={locale}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={onDateChange}
            />
          </div>
        </div>
        <Table columns={columns} dataSource={leesonList} bordered 
          rowClassName={function(record, index) {return record.instanceStatus === 1 ? '' : 'ant-table-gray-row'}}
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
    </div>
  );
}

export default LessonListItem;
