/**
* @file: description
* @author: huguantao
* @Date: 2021-06-05 22:10:18
* @LastEditors: huguantao
* @LastEditTime: 2021-06-05 22:10:18
 */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Form, Select, Input, Button, Table, Modal, Drawer, Upload, TimePicker, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import JustifyText from '../../components/justifyText';
import GapLine from '../../components/GapLine';
import { request } from '../../utils/request';
import { getBase64 } from '../../utils/helper';
import Toast from '../../components/Toast/Toast';
import { debounce } from  '../../utils/useDebounce';

import reUploadImg from '../../assets/img/reUpload.png';
import editIconW from '../../assets/img/icon-edit-w.png';
import '../../styles/lesson/detail.scss';

// IMP: usestate在select组件中不更新了，偷懒写进全局来用。 每次编辑和新建，都要初始化他们
let studioId = '';
let classRoomId = '';
let teacherId = '';
let timeRange = ['00:00', '00:00'];
let uploadImgId = '';
let courseDate = null;

function LessonDetail(props) {
  const courseInstanceId = props.match.params.id;
  const [courseDetail, setCourseDetail] = useState('');
  const [orderList, setOrderList] = useState('');

  // 取消预约相关
  const [cancelUserId, setCancelUserId] = useState('');
  const [cancelCourseDetail, setCancelCourseDetail] = useState({});
  const [confirmVisible, setConfirmVisible] = useState(''); // 取消预约确认弹窗
  const [needDoubleCheck, setNeedDoubleCheck] = useState(false); // 取消学期课需要确认是取消单次还是全部

  // 添加预约相关
  const [allOrderUsers, setAllOrderUsers] = useState([]);
  const [orderUsers, setOrderUsers] = useState([]);
  const [addUserVisible, setAddUserVisible] = useState(false);

  // 枚举
  const [allStudio, setAllStudio] = useState([]);
  const [allClassroom, setAllClassroom] = useState([]);
  const [allTeachers, setAllTeachers] = useState([])
  // 编辑课程相关
  const [editVisible, setEditVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadImgUrl, setUploadImgUrl] = useState('');
  // const [uploadImgId, setUploadImgId] = useState('');
  // const [studioId, setStudioId] = useState();
  // const [classRoomId, setClassRoomId] = useState();
  // const [teacherId, setTeacherId] = useState();
  // const [timeRange, setTimeRange] = useState(['00:00', '00:00']);
  // const [courseDate, setCourseDate] = useState();
  const [courseWeekDay, setCourseWeekDay] = useState();

  const statusRef = useRef();
  const courseDetailFormRef = useRef(); // 表单实例
  
  const setUploadImgId = (id) => {
    uploadImgId = id;
  }
  const setSelectValueByKey = (key, val) => {
    switch(key) {
      case 'studioId':
        studioId = val;
        // 门店的选择引起教室的联动
        getAllClassRoom(val);
        break;
      case 'classRoomId':
        classRoomId = val;
        break;
      case 'teacherId':
        teacherId = val;
        break;
      case 'timeRange': 
        timeRange = val;
        break;
      case 'courseDate': 
        courseDate = val;
        break;
      default: 
        break
    }
  }

  useEffect(() => {
    request(`/studio/all?studioStatus=1`, 'GET' ).then(res=> {
      setAllStudio(res);
    });
    request(`/student/search/list`, 'GET' ).then(res=> {
      setAllOrderUsers(res.records || []);
    });
  }, []);

  useEffect(() => {
    getCourseDetail();
    getOrderList();
  }, [courseInstanceId]);

  const getAllClassRoom = (id) => {
    return new Promise((resolve, reject) => {
      if(id) {
        request(`/studio/classRoom/search?studioId=${id}`, 'GET' ).then(res=> {
          setAllClassroom(res);
          return resolve();
        });
      } else {
        return resolve();
      }
    });
  }
  const getAllTeachers = () => {
    return new Promise((resolve, reject) => {
      request(`/teacher/search`, 'GET' ).then(res=> {
        setAllTeachers(res.records);
        return resolve();
      });
    });
  }

  const showEditVisible = () => {
    setEditVisible(true);
  }
  const hideEditVisible = () => {
    setEditVisible(false);
  }

  const handleImgInput = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadImgUrl(info.file.response.data.imageUrl);
      setUploadImgId(info.file.response.data.imageId);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setUploadLoading(false);
      });
    }
  }

  const onTimeRangeChange = (val) => {
    setSelectValueByKey('timeRange', [moment(val[0]).format('HH:mm'), moment(val[1]).format('HH:mm')]);
  }

  const onCourseDateChange = (date) => {
    setSelectValueByKey('courseDate', moment(date).format('YYYY-MM-DD'));
    setCourseWeekDay(moment(date).format('E'));
  }

  const submitCourse = (params) => {
    let submitParams = {
      courseInstanceId,
      studioId,
      classRoomId,
      teacherId,
      imgId: uploadImgId,
      startTime: timeRange[0],
      endTime: timeRange[1],
      instanceStatus: statusRef.current.input.checked ? 1 : 0,
      courseDate,
      ...params
    }
    request(`/course/courseInstance/edit`, 'POST', submitParams, {} ).then(res=> {
      hideEditVisible();
      Toast.show({mess: '编辑成功'});
      getCourseDetail();
    })
  }


  const getCourseDetail = () => {
    request(`/course/courseInstance/detail?courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
      setCourseDetail(res);
      setUploadImgUrl(res.imgUrl);
      setUploadImgId(res.imgId);
      setSelectValueByKey('studioId', res.studioId);
      setSelectValueByKey('classRoomId', res.classRoomId);
      setSelectValueByKey('teacherId', res.teacherId);
      setSelectValueByKey('timeRange', [res.startTime, res.endTime]);
      setSelectValueByKey('courseDate', res.courseDate);

      setCourseWeekDay(res.weekDay);
      
      Promise.all([
        getAllClassRoom(res.studioId),
        getAllTeachers()]).then(res => {
        courseDetailFormRef.current && courseDetailFormRef.current.resetFields();
      })
    });
  }

  const getOrderList = () => {
    request(`/course/courseInstance/order/list?courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
      setOrderList(res);
    });
  }

  const preCancelOrder = (userId, orderId) => {
    setCancelUserId(userId);
    const params = {
      courseInstanceId,
      userId,
      orderId
    };
    request(`/course/courseInstance/order/cancel/check`, 'POST', params ).then(res=> {
      setCancelCourseDetail(res);
      if(LessonDetail.expired) {
        cancelOrder(0)
      } else {
        if(res.courseType === 1) {
          setNeedDoubleCheck(true);
        } else {
          setNeedDoubleCheck(false);
        }
        setConfirmVisible(true);
      }
    });
  }

  const cancelOrder = (cancelType) => {
    if(cancelType === 0 && !needDoubleCheck) {
      setConfirmVisible(false);
    } else {
      const params = {cancelType, userId: cancelUserId, courseInstanceId, 
        orderId: cancelCourseDetail.orderId, 
        canCancelCnt: cancelType === 0 ? 1 : cancelCourseDetail.canCancelCnt
      };
      request(`/course/courseInstance/order/cancel`, 'POST', params ).then(res=> {
        Toast.show({mess: '取消预约成功'});
        setConfirmVisible(false);
        getOrderList();
      });
    }
  }

  // const searchOrderUser = (userName) => {
  //   request(`/student/search?input=${userName}&courseInstanceId=${courseInstanceId}`, 'GET' ).then(res=> {
  //     setAllOrderUsers(res.records || []);
  //   });
  // }

  // const onSearchOrderInput = (val) => {
  //   searchOrderUser(val);
  // }

  const batchOrders = () => {
    const params = {
      userIds: orderUsers,
      courseInstanceId: courseInstanceId
    }
    request(`/course/courseInstance/order/batch`, 'POST', params ).then(res=> {
      Toast.show({mess: '预约成功'});
      setOrderUsers([]);
      getCourseDetail();
      getOrderList();
      setAddUserVisible(false);
    });
  }

  const textData1 = [{
    leftText: '课程类型',
    rightText: courseDetail.courseTypeDesc
  }, {
    leftText: '课程类别',
    rightText: courseDetail.courseCategory
  }, {
    leftText: '舞蹈种类',
    rightText: courseDetail.danceCategory
  }, {
    leftText: '舞蹈/歌曲名称',
    rightText: courseDetail.danceName
  }, {
    leftText: '课程日期',
    rightText: courseDetail.courseDate
  }, {
    leftText: '课程时间',
    rightText: courseDetail.startTime + '-' +courseDetail.endTime
  }, {
    leftText: '课程星期',
    rightText: courseDetail.weekDay
  }, {
    leftText: '课程价格',
    rightText: courseDetail.coursePrice + '课时'
  }, {
    leftText: '教室名称',
    rightText: courseDetail.classRoomName
  }, {
    leftText: '老师姓名',
    rightText: courseDetail.teacherName
  }, {
    leftText: '人数上限',
    rightText: courseDetail.ceilingCnt
  }];

  const textData2 = [{
    leftText: '门店名称',
    rightText: courseDetail.studioName
  }, {
    leftText: '门店地址',
    rightText: courseDetail.studioAddress
  }, {
    leftText: '适合人群',
    rightText: courseDetail.crowd
  }, {
    leftText: '取消规则',
    rightText: courseDetail.cancelTime + '小时'
  }];

  const columns = [
    {
      title: '学员头像',
      dataIndex: 'avatarUrl',
      render: (text, row, index) => {
        return <img className="lesson-detail-right-table-headimg" src={text} alt="" />;
      },
    },
    {
      title: '学员姓名',
      dataIndex: 'userName'
    },
    {
      title: '学员ID',
      dataIndex: 'userId'
    },
    {
      title: '学员电话',
      dataIndex: 'mobile'
    },
    {
      title: '学员邮箱',
      dataIndex: 'userEmail'
    },
    {
      title: '操作',
      dataIndex: 'userId',
      render: (text, row, index) => {
        return <div className="lesson-detail-right-table-opt">
          <i className="iconfont icon-close cursor" onClick={() => preCancelOrder(text, row.orderId)}></i>
        </div>;
      },
    },
  ];

  const { Option } = Select;
  const { TextArea } = Input;
  const courseForm = useCallback(() => {
    return <Form onFinish={submitCourse} ref={courseDetailFormRef} initialValues={courseDetail}>
      <Form.Item>
        <div className="form-input display-flex">
          <span className="form-input-label">课程类型： </span>
          <Input disabled defaultValue={courseDetail.courseTypeDesc} />
        </div>
      </Form.Item>
      <Form.Item name="courseCategory" rules={[{ required: true, message: '请填写课程类别' }]} >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程类别： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.courseCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceCategory"  rules={[{ required: true, message: '请填写舞蹈种类' }]}>
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>舞蹈种类： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.danceCategory} />
        </div>
      </Form.Item>
      <Form.Item name="danceName">
        <div className="form-input display-flex">
          <span className="form-input-label">舞蹈/歌曲名称： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.danceName} />
        </div>
      </Form.Item>

      {/* 日期不可编辑 */}
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程日期： </span>
          {/* <Input placeholder="" defaultValue={courseDetail.courseDate} /> */}
          <DatePicker onChange={onCourseDateChange} defaultValue={moment(courseDate)} />
        </div>
      </Form.Item>
      <Form.Item name="" >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程时间： </span>
          <TimePicker.RangePicker locale={locale} 
            defaultValue={[moment(courseDetail.startTime || '00:00:00', 'HH:mm:ss'), moment(courseDetail.endTime || '00:00:00', 'HH:mm:ss')]} 
            onChange={onTimeRangeChange} />
        </div>
      </Form.Item>
      <Form.Item name="" >
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程星期： </span>
            <Input placeholder="" value={'星期' + courseWeekDay} disabled />
        </div>
      </Form.Item>
      <Form.Item name="coursePrice">
        <div className="form-input display-flex">
          <span className="form-input-label"><span className="red-text">*</span>课程价格： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.coursePrice} />
        </div>
      </Form.Item>
      {/* 课程价格不可编辑 */}

      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">门店名称： </span>
          <Select key="studio" onSelect={(val) => setSelectValueByKey('studioId',val)} defaultValue={courseDetail.studioId} className="inline-block filter-item">
            {
              allStudio.map((item, index) => {
                return <Option value={item.studioId} key={index}>{item.studioName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">教室名称： </span>
          <Select key="classroom" onSelect={(val) => setSelectValueByKey('classRoomId',val)} defaultValue={courseDetail.classRoomId} className="inline-block filter-item">
            {
              allClassroom.map((item, index) => {
                return <Option value={item.classRoomId} key={index}>{item.classRoomName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item >
        <div className="form-input display-flex">
          <span className="form-input-label">老师姓名： </span>
          <Select onSelect={(val) => setSelectValueByKey('teacherId',val)} defaultValue={courseDetail.teacherId} className="inline-block filter-item">
            {
              allTeachers.map((item, index) => {
                return <Option value={item.teacherId} key={index}>{item.teacherName}</Option>
              })
            }
          </Select>
        </div>
      </Form.Item>
      <Form.Item name="ceilingCnt">
        <div className="form-input display-flex">
          <span className="form-input-label">人数上限： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.ceilingCnt} />
        </div>
      </Form.Item>
      <Form.Item name="cancelTime">
        <div className="form-input display-flex">
          <span className="form-input-label">取消时间： </span>
          <Input placeholder="请填写" type="number" defaultValue={courseDetail.cancelTime} />
        </div>
      </Form.Item>
      <Form.Item name="crowd">
        <div className="form-input display-flex">
          <span className="form-input-label">适合人群： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.crowd} />
        </div>
      </Form.Item>
      <Form.Item name="title">
        <div className="form-input display-flex">
          <span className="form-input-label">简介标题： </span>
          <Input placeholder="请填写" defaultValue={courseDetail.title} />
        </div>
      </Form.Item>
      <Form.Item name="content">
        <div className="form-input display-flex">
          <span className="form-input-label">正文编辑： </span>
          {/* <Input placeholder="请填写" defaultValue={courseDetail.content} /> */}
          <TextArea placeholder="请填写" defaultValue={courseDetail.content} className="form-textarea" />
        </div>
      </Form.Item>
      <div className="form-input">
        <Input type="checkbox" ref={statusRef} defaultChecked={courseDetail.status === 1} className="form-input-checkbox" />生效
      </div>
      <div className="draw-footer">
        <Form.Item>
          <Button onClick={hideEditVisible}>取消</Button>
          <Button htmlType="submit" type="primary">确认</Button>
        </Form.Item>
      </div>
    </Form>
  }, [courseDetail, allStudio, allClassroom, allTeachers, courseWeekDay])

  return (
    <div className="lesson-detail-page common-page-container">
      <div className="lesson-detail-page-left">
        <div className="common-title-text">待进行</div>
        <div className="box-shadow-card lesson-detail-page-left-content">
          {
            textData1.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          <GapLine />
          {
            textData2.map((item, index) => {
              return <JustifyText key={item.leftText} leftText={item.leftText} rightText={item.rightText} />
            })
          }
          <GapLine />
          <div className="small-text-text">{courseDetail.title}</div>
          <div className="small-text-text">{courseDetail.content}</div>
          
          <div className="bottom-opt">
            <div className="form-input">
              <Input type="checkbox" checked={courseDetail.status === 1 ? true : false} className="form-input-checkbox unEditable-checkbox-input" />生效
            </div>
            <i className="iconfont icon-write1 icon-edit-opt cursor" onClick={showEditVisible}>
              <img src={editIconW} alt="" />
            </i>
          </div>
        </div>
      </div>
      <div className="lesson-detail-page-right">
        <div className="common-title-text">
          <span>预约详情</span>
          <div className="opts">
            <Button type="primary" className="lesson-detail-page-right-yuyue-btn" onClick={() => setAddUserVisible(true)}>
              <i className="iconfont icon-add"></i>添加预约
            </Button>
          </div>
        </div>
        <Table columns={columns} dataSource={orderList} bordered 
          pagination={{ position: ['bottomLeft'] }}
        />
      </div>
      <Modal
        className="cancel-course-order-modal"
        maskClosable={false}
        destroyOnClose={true}
        centered
        visible={confirmVisible}
        footer={<>
          <Button type="" shape="" onClick={(e) => cancelOrder(0)}>
            {needDoubleCheck ? '当前' : '取消'}
          </Button>
          <Button type="primary" shape="" onClick={(e) => cancelOrder(1)}>
          {needDoubleCheck ? '全部' : '确认'}
          </Button>
        </>}
        onCancel={() => setConfirmVisible(false)}
      >
        <p>{needDoubleCheck ? '您希望取消本节课程预约还是全部课程预约？' : '您确定要取消当前预约吗？'}</p>
      </Modal>

      <Drawer
        id="drawer-view"
        width={380}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => setAddUserVisible(false)}
        visible={addUserVisible}
        destroyOnClose={true}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setAddUserVisible(false)} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={batchOrders} 
              type="primary" disabled={orderUsers.length===0}
            >确认</Button>
          </div>
        }
      >
        <Select placeholder="输入学员姓名或邮箱搜索" mode="multiple" style={{width: '100%'}}
          filterOption={(inputValue, option) =>{
            const name = option.name.toLowerCase();
            const email = option.email.toLowerCase();
            const val = inputValue.toLowerCase();
            return name.indexOf(val) > -1 ||email.indexOf(val) > -1;
            // return option.name.indexOf(inputValue) > -1 || option.email.indexOf(inputValue) > -1
          }}
          onChange={(val, opt) => {
            let users = [];
            opt.map(item => {
              return users.push(item.key)
            })
            setOrderUsers(users)
          }}
          // onSearch={debounce(val => onSearchOrderInput(val), 1000)}
        >
          {
            allOrderUsers.map((item) => {
              return <Option value={item.userId} key={item.userId}
                name={item.userName} email={item.userEmail}>
                <div className="add-order-user-option">
                  <p ><img src={item.avatarUrl} alt=""></img>{item.userName}</p>
                  <p>{item.userEmail}</p>
                </div>
              </Option>
            })
          }
        </Select>
      </Drawer>
    
      <Drawer
        id="drawer-edit"
        width={380}
        title=""
        placement="right"
        closable={false}
        maskClosable={false}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="/api/v1/admin/image/upload"
          accept="image/jpeg, image/png"
          // beforeUpload={beforeUpload}
          onChange={handleImgInput}
        >
          {
            uploadImgUrl ? <>
              <div class="placeholder-img-wrap">
                <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" />
              </div>
              {/* <img src={uploadImgUrl} alt="" className="ant-upload-placeholder-img" /> */}
              <i className="iconfont icon-upload-img">
                <img src={reUploadImg} alt=""></img>
              </i>
            </>: 
            <div>
              {uploadLoading ? <LoadingOutlined /> : 
                <span className="add-icon text-center cursor">+</span>}
              <div style={{ marginTop: 8 }}>上传课程图片</div>
            </div>
          }
        </Upload>
        {courseForm()}
      </Drawer>
    </div>
  );
}

export default LessonDetail;
