/**
* @file: justifyText： 左右分布的文字样式
* @author: huguantao
* @Date: 2021-06-08 23:03:35
* @LastEditors: huguantao
* @LastEditTime: 2021-06-08 23:12:12
 */
import React from 'react';
import './index.scss';

function JustifyText(props) {
  if(!props.leftText) {
    return null
  }
  return (
    <div className="component-justify-text">
      <span>{props.leftText}</span>
      <span>{props.rightText}</span>
    </div>
  );
}


export default JustifyText;
